const CustomLoader = () => {
  return (
    <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580]  ">
      <div className="flex h-screen items-center justify-center ">
        <div className="h-13 w-13 animate-spin rounded-full border-4 border-solid border-[#DFC749] border-t-transparent"></div>
      </div>
    </div>
  );
};

export default CustomLoader;
