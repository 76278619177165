import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface ChartThreeState {
  series: number[];
}

const options: ApexOptions = {
  chart: {
    fontFamily: 'Satoshi, sans-serif',
    type: 'donut',
  },
  colors: ['#594255', '#59425533', '#f2e5a4', '#dfc749'],
  labels: ['Page View', 'Connection', 'View Per User', 'Engagement Time'],
  legend: {
    show: false,
    position: 'bottom',
  },

  plotOptions: {
    pie: {
      donut: {
        size: '65%',
        background: 'transparent',
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 380,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

interface ChartDataProps {
  chartData: any;
}

const ChartThree: React.FC<ChartDataProps> = (chartData) => {
  const chartDatas = chartData.chartData;
  const [state, setState] = useState<ChartThreeState>({
    series: [0, 0, 0, 0],
    // series: [65, 34, 12, 56],
  });

  const [timeRange, setTimeRange] = useState('Monthly');

  const handleTimeRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeRange(e.target.value);
  };

  const processChartData = (data: any[]) => {
    const totalViews = data.reduce((sum, item) => sum + item.view_count, 0);
    const totalConnections = data.length;
    const viewPerUser =
      totalConnections === 0 ? 0 : (totalViews / totalConnections).toFixed(2);
    const totalEngagementTime = data.reduce(
      (sum, item) => sum + item.engagement_time,
      0,
    );

    // Calculate average engagement time per view
    const engagementTime =
      totalConnections > 0
        ? Math.round(totalEngagementTime / totalConnections)
        : 0;

    // // Convert milliseconds to hours, minutes, and seconds
    // const totalEngagementTimeSeconds = Math.floor(engagementTime / 1000);
    // const totalEngagementTimeMinutes = Math.floor(
    //   totalEngagementTimeSeconds / 60,
    // );
    // const totalEngagementTimeHours = Math.floor(
    //   totalEngagementTimeMinutes / 60,
    // );
    // const remainingMinutes = totalEngagementTimeMinutes % 60;
    // const remainingSeconds = totalEngagementTimeSeconds % 60;

    // let formattedEngagementTime = '';

    // if (totalEngagementTimeHours > 0) {
    //   formattedEngagementTime = `${totalEngagementTimeHours}h ${remainingMinutes}m ${remainingSeconds}s`;
    // } else {
    //   formattedEngagementTime = `${remainingMinutes}m ${remainingSeconds}s`;
    // }
    // console.log('formated time', formattedEngagementTime);

    return [totalViews, totalConnections, viewPerUser, engagementTime];
  };

  useEffect(() => {
    if (chartDatas !== undefined) {
      let filteredData = chartDatas;

      if (timeRange === 'Monthly') {
        // Filter data for the past month
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        filteredData = chartDatas.filter(
          (item: any) => new Date(item.created_at) >= oneMonthAgo,
        );
      } else if (timeRange === 'Yearly') {
        // Filter data for the past year
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        filteredData = chartDatas.filter(
          (item: any) => new Date(item.created_at) >= oneYearAgo,
        );
      }

      const seriesData = processChartData(filteredData);
      setState({ series: seriesData });
    }
  }, [timeRange, chartDatas]);

  return (
    <div className="sm:px-7.5 col-span-12 rounded-lg border border-stroke bg-white px-5 pb-5 pt-7.5 shadow-default xl:col-span-6">
      <div className="mb-3 justify-between gap-4 sm:flex">
        <div>
          <h5 className="text-xl font-semibold text-black dark:text-white">
            Visitors Analytics
          </h5>
        </div>
        <div>
          <div className="relative z-20 inline-block">
            <select
              value={timeRange}
              onChange={handleTimeRangeChange}
              className="relative z-20 inline-flex appearance-none bg-transparent py-1 pl-3 pr-8 text-sm font-medium outline-none"
            >
              <option value="Monthly" className="dark:bg-boxdark">
                Monthly
              </option>
              <option value="Yearly" className="dark:bg-boxdark">
                Yearly
              </option>
            </select>
            <span className="absolute right-3 top-1/2 z-10 -translate-y-1/2">
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.47072 1.08816C0.47072 1.02932 0.500141 0.955772 0.54427 0.911642C0.647241 0.808672 0.809051 0.808672 0.912022 0.896932L4.85431 4.60386C4.92785 4.67741 5.06025 4.67741 5.14851 4.60386L9.09079 0.896932C9.19376 0.793962 9.35557 0.808672 9.45854 0.911642C9.56151 1.01461 9.5468 1.17642 9.44383 1.27939L5.50155 4.98632C5.22206 5.23639 4.78076 5.23639 4.51598 4.98632L0.558981 1.27939C0.50014 1.22055 0.47072 1.16171 0.47072 1.08816Z"
                  fill="#637381"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.22659 0.546578L5.00141 4.09604L8.76422 0.557869C9.08459 0.244537 9.54201 0.329403 9.79139 0.578788C10.112 0.899434 10.0277 1.36122 9.77668 1.61224L9.76644 1.62248L5.81552 5.33722C5.36257 5.74249 4.6445 5.7544 4.19352 5.32924C4.19327 5.32901 4.19377 5.32948 4.19352 5.32924L0.225953 1.61241C0.102762 1.48922 -4.20186e-08 1.31674 -3.20269e-08 1.08816C-2.40601e-08 0.905899 0.0780105 0.712197 0.211421 0.578787C0.494701 0.295506 0.935574 0.297138 1.21836 0.539529L1.22659 0.546578ZM4.51598 4.98632C4.78076 5.23639 5.22206 5.23639 5.50155 4.98632L9.44383 1.27939C9.5468 1.17642 9.56151 1.01461 9.45854 0.911642C9.35557 0.808672 9.19376 0.793962 9.09079 0.896932L5.14851 4.60386C5.06025 4.67741 4.92785 4.67741 4.85431 4.60386L0.912022 0.896932C0.809051 0.808672 0.647241 0.808672 0.54427 0.911642C0.500141 0.955772 0.47072 1.02932 0.47072 1.08816C0.47072 1.16171 0.50014 1.22055 0.558981 1.27939L4.51598 4.98632Z"
                  fill="#637381"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div className="mb-2">
        <div id="chartThree" className="mx-auto flex justify-center">
          <ReactApexChart
            options={options}
            series={state.series}
            // series={state.series.map(
            //   (value, index) => (index === 3 ? parseFloat(value) : value), // Convert formatted string to number for chart
            // )}
            type="donut"
          />
        </div>
      </div>

      <div className="-mx-8 flex flex-wrap items-center justify-center gap-y-3">
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-purpletacx"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
              <span> Page View </span>
              <span>{state.series[0]} </span>
            </p>
          </div>
        </div>
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#59425533]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
              <span> Connection </span>
              <span> {state.series[1]} </span>
            </p>
          </div>
        </div>
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#f2e5a4]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
              <span> View Per User </span>
              <span>{state.series[2]}</span>
            </p>
          </div>
        </div>
        <div className="sm:w-1/2 w-full px-8">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-yellowtacx"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-white">
              <span> Engagement Time </span>
              <span> {state.series[3]}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartThree;
