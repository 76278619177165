import axios from 'axios';
const URL = import.meta.env.VITE_API_URL;
// const ipLocationUrl = import.meta.env.VITE_LOCATION
const ipLocationUrl = 'https://ipapi.co/json/';

const jsonconfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

export const googleSignUp = async (credential: string) => {
  try {
    const response = await axios.post(
      `${URL}/google-signUp`,
      {
        token: credential,
      },
      jsonconfig,
    );
    return response.data;
  } catch (error) {
    console.error('Error during Google signUp:', error);
    throw error;
  }
};

export const googleLogin = async (data: {
  token?: string;
  email?: string;
  password?: string;
}) => {
  try {
    const response = await axios.post(`${URL}/login`, { data }, jsonconfig);
    return response.data;
  } catch (error) {
    console.error('Error during Google login:', error);
    throw error;
  }
};

export const completeSignUp = async (data: {
  token?: any;
  userData?: any;
  password?: any;
}) => {
  try {
    const response = await axios.post(
      `${URL}/complete-signup`,
      {
        data,
      },
      jsonconfig,
    );
    return response.data;
  } catch (error) {
    console.error('Error during Google login:', error);
    throw error;
  }
};

export const completeGoogleSignUp = async (userDetails: any) => {
  try {
    const response = await axios.post(
      `${URL}/complete-google-signup`,
      userDetails,
      jsonconfig,
    );
    return response.data;
  } catch (error) {
    console.error('Error during Google login:', error);
    throw error;
  }
};

export const verificationEmail = async (data: { email: string }) => {
  try {
    const response = await axios.post(`${URL}/send-verification-email`, data);
    return response.data;
  } catch (error) {
    console.error('Error during Google login:', error);
    throw error;
  }
};

export const makepayment = async () => {
  try {
    return await axios.post(`${URL}/payment/makePayment`);
  } catch (err) {
    console.error(err);
  }
};

export const ProfileData = async () => {
  try {
    return await axios.get(`${URL}/profile`, jsonconfig);
  } catch (error) {
    console.error(error);
  }
};

export const editProfileData = async (data: any) => {
  try {
    return await axios.put(`${URL}/edit-user-information`, data, jsonconfig);
  } catch (error) {
    console.error(error);
  }
};

export const sendOtp = async (data: { email: string }) => {
  try {
    return await axios.post(`${URL}/send-otp`, data);
  } catch (error) {
    console.error('Error in sending otp', error);
  }
};

export const verifyOtp = async (data: { email: string; otp: string }) => {
  try {
    return await axios.post(`${URL}/verify-otp`, data);
  } catch (error) {
    console.error('Error in sending otp', error);
  }
};

export const resendOtp = async (data: { email: string }) => {
  try {
    return await axios.post(`${URL}/resend-otp`, data);
  } catch (error) {
    console.error('Error in sending otp', error);
  }
};

export const updatePassword = async (data: {
  email: string;
  password: any;
}) => {
  try {
    return await axios.post(`${URL}/change-password`, data);
  } catch (error) {
    console.error('Error changing password', error);
  }
};

export const SignOut = async () => {
  try {
    return await axios.get(`${URL}/logout`, {
      withCredentials: true,
    });
  } catch (err) {
    console.error(err);
  }
};

// export const AddPlan = async (data: {
//   plan_name: string;
//   add_user: number;
//   price: number;
//   expireDate: any;
// }) => {
//   try {
//     return await axios.post(`${URL}/add-plan`, data, jsonconfig);
//   } catch (error) {
//     console.error('Error storing plan', error);
//   }
// };

// export const getPlan = async () => {
//   try {
//     return await axios.get(`${URL}/get-plan`, jsonconfig);
//   } catch (error) {
//     console.error('Error getting plan', error);
//   }
// };

export const getAddress = async () => {
  try {
    return await axios.get(`${URL}/get-address`, jsonconfig);
  } catch (error) {
    console.error('Error getting address', error);
  }
};

export const addShippingAddress = async (data: {
  shipping_type: string;
  shipping_method: string;
  address: string;
  suite: string;
  country: {
    key: string;
    label: string;
  };
  state: {
    key: string;
    label: string;
  };
  city: {
    key: string;
    label: string;
  };
  postal_code: string;
}) => {
  try {
    return await axios.post(`${URL}/add-shipping-address`, data, jsonconfig);
  } catch (error) {
    console.error('Error storing shipping information', error);
  }
};

export const editShippingAddress = async (data: {
  // shipping_type: string;
  // shipping_method: string;
  address: string;
  suite: string;
  country: {
    key: string;
    label: string;
  };
  state: {
    key: string;
    label: string;
  };
  city: {
    key: string;
    label: string;
  };
  postal_code: string;
}) => {
  try {
    return await axios.post(`${URL}/edit-shipping-address`, data, jsonconfig);
  } catch (error) {
    console.error('Error storing shipping information', error);
  }
};

export const createCard = async (data: any) => {
  try {
    const response = await axios.post(
      `${URL}/card-editor/create`,
      data,
      jsonconfig,
    );
    return response.data;
  } catch (error) {
    console.error('Error during card creation:', error);
    throw error;
  }
};

// Function to update a card by ID
export const updateCardById = async (cardId: string, data: any) => {
  try {
    const response = await axios.put(
      `${URL}/card-editor/cards/${cardId}`,
      data,
      jsonconfig,
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating card ${cardId}:`, error);
    throw error;
  }
};

// Function to fetch all cards for the authenticated user
export const getAllCards = async () => {
  try {
    const response = await axios.get(
      `${URL}/card-editor/cards`,
      jsonconfig, // Add your authentication token/config here if required
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching all cards:', error);
    throw error;
  }
};

// Function to fetch a specific card by ID
export const getCardById = async (cardId: string | undefined) => {
  try {
    const response = await axios.get(
      `${URL}/card-editor/cards/${cardId}`,
      jsonconfig, // Add your authentication token/config here if required
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching card ${cardId}:`, error);
    throw error;
  }
};

// Function to upload a file
export const uploadFile = async (file: any) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${URL}/card-editor/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

//delete card
export const deleteCard = async (cardId: number) => {
  try {
    const response = await axios.delete(
      `${URL}/card-editor/deleteCard/${cardId}`,
      jsonconfig,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//card Engagement time count
export const trackCardEngagement = async (data: any) => {
  try {
    return await axios.post(`${URL}/card-editor/track-card-engagement`, data);
  } catch (error) {
    console.error('Error tarcking engagement: ', error);
  }
};

//get card Engagement time data
export const getCardEngagementData = async (cardId: number) => {
  try {
    const response = await axios.get(
      `${URL}/card-editor/get-card-engagement-data/${cardId}`,
      
    );
    return response.data;
  } catch (error) {
    console.error('Error getting engagement data:', error);
  }
};

//create section
export const createSection = async (data: any) => {
  try {
    return await axios.post(
      `${URL}/card-editor/createSection`,
      data,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error at time of create section api', error);
  }
};

//get section
export const getSection = async () => {
  try {
    return await axios.get(`${URL}/card-editor/get-sections`, jsonconfig);
  } catch (error) {
    console.error('Error getting section api:', error);
  }
};

//update section by section id
export const updateSection = async (sectionId: number, updatedData: any) => {
  try {
    return await axios.put(
      `${URL}/card-editor/update-sections/${sectionId}`,
      updatedData,
    );
  } catch (error) {
    console.error('Error updating section api:', error);
  }
};

//delete section
export const deleteSection = async (sectionId: number) => {
  try {
    return await axios.delete(
      `${URL}/card-editor/delete-sections/${sectionId}`,
    );
  } catch (error) {
    console.error('Error deleting section api:', error);
  }
};

//stripe payment
export const StripeCheckOut = async (data: any) => {
  try {
    return await axios.post(`${URL}/payment/StripeCheckOut`, data, jsonconfig);
  } catch (err) {
    console.error('error');
  }
};

export const StripePaymentSuccess = async (session_id: any) => {
  try {
    return await axios.post(
      `${URL}/payment/stripe-payment-success`,
      session_id,
      jsonconfig,
    );
  } catch (error) {
    console.error('Error in stripe payment success');
  }
};

export const upgradePlan = async (data: any) => {
  try {
    return await axios.post(`${URL}/payment/upgrade-plan`, data, jsonconfig);
  } catch (error) {
    console.error('Error in upgrade plan');
  }
};

export const cancelSubscription = async (data: any) => {
  try {
    return await axios.post(`${URL}/payment/cancel-subscription`, data);
  } catch (error) {
    console.error('Error in cancel subscription', error);
  }
};

//subscription
export const getSubscription = async () => {
  try {
    return await axios.get(`${URL}/payment/get-subscription`, jsonconfig);
  } catch (error) {
    console.error('Error retrieving subscription data', error);
  }
};

//Invite users
export const InviteUserByEmail = async (data: any) => {
  try {
    return await axios.post(`${URL}/invite-user-email`, data, jsonconfig);
  } catch (error) {
    console.error('Error invite user email api', error);
  }
};

export const InviteUserCsv = async (data: any) => {
  try {
    return await axios.post(`${URL}/invite-user-csv`, data, jsonconfig);
  } catch (error) {
    console.error('Error invite user csv api', error);
  }
};

export const reinviteUser = async (data: any) => {
  try {
    return await axios.post(`${URL}/reinvite-user`, data, jsonconfig);
  } catch (error) {
    console.error('Error invite user email api', error);
  }
};

export const InvitedUserLogin = async (data: any) => {
  try {
    return await axios.post(`${URL}/accept-invitation`, data, jsonconfig);
  } catch (error) {
    console.error('Error accepting invitation api:', error);
  }
};

export const getInvitedUsers = async () => {
  try {
    return await axios.get(`${URL}/get-invited-users`, jsonconfig);
  } catch (error) {
    console.error('Error retrieving invited users api', error);
  }
};

export const editInvitedUserStatus = async (data: any) => {
  try {
    return await axios.put(`${URL}/edit-invite-user-status`, data, jsonconfig);
  } catch (error) {
    console.error('Error retrieving invited users api', error);
  }
};

export const deleteInvitedUsers = async (Id: number) => {
  try {
    return await axios.delete(`${URL}/delete-invited-users/${Id}`, jsonconfig);
  } catch (error) {
    console.error('Error deleting invited users api', error);
  }
};

//Gemeni Ai
export const generateText = async (prompt: any) => {
  try {
    console.error(prompt);

    return await axios.post(`${URL}/ai/generate-text`, prompt);
  } catch (error) {
    console.error('Error generating text api', error);
  }
};

//get user location country
// geoLocation.js
export const getCountry = async () => {
  const response = await fetch(`${ipLocationUrl}`);
  const data = await response.json();
  return { country: data.country, currency: data.currency };
};

//get all industry
export const getIndustryList = async () => {
  try {
    return await axios.get('https://api.smartrecruiters.com/v1/industries');
  } catch (error) {
    console.error('Error retrieving industry list', error);
  }
};
