import { useEffect, useState } from 'react';

const AboutUs = ({
  // videoUrl = 'https://youtu.be/SBGdvxi2JmU?si=2x_x9H88VWB8iiYf',
  textContent = 'Welcome to our website! We are glad to have you here.',
  fontColor = '#594255',
  hideText = false,
  aboutUsTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: 'text-xl',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
    lineHeight: '100',
  },
  aboutUsPadding = [10, 10, 10, 10],
  aboutUsMargin = [0, 0, 0, 0],
  globalStyling = {
    fontColor: '#000000',
    linkColor: '#000000',
    fontSize: 'text-xl',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
}) => {
  // Youtube video url
  // const getEmbedUrl = (url: any) => {
  //   const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  //   const match = url.match(youtubeRegex);
  //   if (match && match[1]) {
  //     return `https://www.youtube.com/embed/${match[1]}`;
  //   }
  //   return url; // Fallback to the original URL for non-YouTube videos
  // };
  let handleGlobalStyling = () => {
    let data = {
      fontColor: globalStyling.fontColor,
      textFontSize: globalStyling.fontSize,
    };
    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.fontColor = globalStyling.fontColor;
          //  fontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
          data.fontColor = fontColor;
          //  fontColor = fontColor;
        } else {
          data.fontColor = globalStyling.fontColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontSize')) {
          data.textFontSize = globalStyling.fontSize;
          //  fontColor = globalStyling.fontColor;
        }
      } else {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            'aboutUsTypography.nameFontSize',
          )
        ) {
          data.textFontSize = aboutUsTypography.nameFontSize;
        } else {
          data.textFontSize = globalStyling.fontSize;
        }
      }
    } else {
      if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
        data.fontColor = fontColor;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes(
          'aboutUsTypography.nameFontSize',
        )
      ) {
        data.textFontSize = aboutUsTypography.nameFontSize;
      }
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );

  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);
  // const getEmbedUrl = (url: any) => {
  //   const youtubeRegex =
  //     /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  //   const vimeoRegex =
  //     /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\w+\/)?|album\/(?:\d+\/)?|video\/|)(\d+)(?:$|\/|\?)/;

  //   const youtubeMatch = url.match(youtubeRegex);
  //   if (youtubeMatch && youtubeMatch[1]) {
  //     return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
  //   }

  //   const vimeoMatch = url.match(vimeoRegex);
  //   if (vimeoMatch && vimeoMatch[1]) {
  //     return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
  //   }

  //   return url; // Fallback to the original URL for non-YouTube and non-Vimeo videos
  // };

  return (
    <div
      className={`my-2 flex flex-col `}
      style={{
        padding: `${aboutUsPadding?.[0]}px ${aboutUsPadding?.[1]}px ${aboutUsPadding?.[2]}px ${aboutUsPadding?.[3]}px`,
        margin: `${aboutUsMargin?.[0]}px ${aboutUsMargin?.[1]}px ${aboutUsMargin?.[2]}px ${aboutUsMargin?.[3]}px`,
      }}
    >
      {/* <div className="mb-4 w-full max-w-lg">
        {getEmbedUrl(videoUrl) ? (
          <iframe
            width="100%"
            height="180px"
            // src={videoUrl}
            src={getEmbedUrl(videoUrl)}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded video"
          ></iframe>
        ) : (
          <video width="100%" controls>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div> */}
      {hideText && (
        <div
          className={`p-2 ${aboutUsTypography.typographyWeight} break-words text-center`}
          style={{
            color: globalStylingData.fontColor,
            fontFamily: aboutUsTypography.family,
            fontStyle: aboutUsTypography.typographyStyle,
            lineHeight: aboutUsTypography.lineHeight,
            fontSize: globalStylingData.textFontSize,
          }}
        >
          {textContent}
        </div>
      )}
    </div>
  );
};

export default AboutUs;
