// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: null,
  subscription: null,
};
const initialStateSelectedItem = {
  selectedItem: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload.subscription;
    },
    clearUser: (state) => {
      state.user = null;
      state.token = null;
      state.subscription = null;
    },
  },
});

const selectedItemSlice = createSlice({
  name: 'selectedItem',
  initialState: initialStateSelectedItem,
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    clearSelectedItem: (state) => {
      state.selectedItem = null;
    },
  },
});

export const { setUser, setSubscription, clearUser } = userSlice.actions;
export const { setSelectedItem, clearSelectedItem } = selectedItemSlice.actions;
// Selector to get user data
export const selectUser = (state: any) => state.user;
export const selectSelectedItem = (state: any) => state.selectedItem.selectedItem;
// Selector to get subscription data
export const selectSubscription = (state: any) => state.user.subscription;

export default userSlice.reducer;
export const selectedItemReducer = selectedItemSlice.reducer;