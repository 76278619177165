import { QRCodeCanvas } from 'qrcode.react';

interface QRModalProps {
  isOpen: boolean;
  QrVal: string;
  setQrVal: (value: string) => void;
  closeQrModal: () => void;
}

const QRModal: React.FC<QRModalProps> = ({
  isOpen,
  QrVal,
  setQrVal,
  closeQrModal,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70">
      <div className="bg-white flex flex-col p-5 m-auto rounded-lg w-[80%] sm:w-[300px] slideInFromTop-animate relative">
        <div className="absolute right-2 top-2">
          <button onClick={closeQrModal}>
            <svg
              width="15"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8307 15.8346L3.16406 3.16797M15.8307 3.16797L3.16406 15.8346"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div className="flex justify-center">
          <QRCodeCanvas
            value={QrVal}
            size={150}
            bgColor="#000000"
            fgColor="#ffffff"
            level="H"
          />
        </div>
      </div>
    </div>
  );
};

export default QRModal;
