import { useEffect, useState } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import { cancelSubscription, getSubscription } from '../../api/api';
import MyIcon from '../../components/CardEditor/icons/MyIcon';
import Loader from '../../common/Loader';
import SubLoader from '../../common/Loader/customLoader';
import { useNavigate } from 'react-router-dom';

interface subscriptionProps {
  plan_name: string;
  user_limit: number;
  subscription_period: string;
  currency: string;
  amount_total: number;
  card_brand: string;
  card_digit: string;
  expire_date: any;
  stripe_subscription_id: string;
  cancellation: string;
  stripe_customer_id: string;
}

const AccountAndBilling = () => {
  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<subscriptionProps>();
  const [loading, setLoading] = useState(true);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);

  useEffect(() => {
    GetSubscription();
  }, []);

  const GetSubscription = async () => {
    setLoading(true);
    try {
      const response = await getSubscription();
      if (response?.data.success) {
        setLoading(false);
        setSubscriptionDetails(response?.data.subscription);
      } else {
        setLoading(false);
        console.error('Failed to fetch subscription details:', response);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching subscription details:', error);
    }
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleCancelSubscription = async () => {
    // GetSubscription()
    setCancelVisible(true);
  };

  const handleConfirmCancel = async () => {
    if (subscriptionDetails) {
      try {
        setCancelLoading(true);
        setCancelVisible(false);
        const response = await cancelSubscription({
          subscriptionId: subscriptionDetails.stripe_subscription_id,
        });
        if (response?.data.success) {
          setCancelLoading(false);
          setSuccessVisible(true);
          setErrorVisible(false);
          setSuccessMessage(response.data.message);
          setTimeout(() => {
            GetSubscription();
          }, 2000);
        } else {
          setCancelLoading(false);
          setSuccessVisible(true);
          setSuccessMessage(
            'Failed to cancel subscription. Please try again after some time!',
          );
          setErrorVisible(true);
          console.error('Failed to cancel subscription:', response);
        }
      } catch (error) {
        console.error('Error canceling subscription:', error);
        setCancelLoading(false);
        setSuccessVisible(true);
        setErrorVisible(true);
        setSuccessMessage(
          'Something went wront. Please try again after some time!',
        );
      }
    }
  };

  const handleSuccessCancelation = () => {
    setSuccessVisible(false);
    setSuccessMessage('');
    if (!errorVisible) {
      navigate('/purchase-plan');
    }
  };

  // const handleDownload = async () => {
  //   try {
  //     // const response = await axios.get(`/api/download-invoice/${invoiceId}`);
  //     const invoiceUrl = 'https://pay.stripe.com/invoice/acct_1PVpiG06zbHV3h1C/test_YWNjdF8xUFZwaUcwNnpiSFYzaDFDLF9RWktKUHZCdXB6WFdHRG1EQndPaWtkbUlmZ1YydjdmLDExMjg2OTg0NQ0200fonWmMlc/pdf?s=ap';

  //     if (invoiceUrl) {
  //       // Create a temporary link element to trigger the download
  //       const link = document.createElement('a');
  //       link.href = invoiceUrl;
  //       link.download = 'invoice.pdf';
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else {
  //       alert('Invoice PDF is not available yet.');
  //     }
  //   } catch (error) {
  //     console.error('Error downloading invoice:', error);
  //   }
  // };

  const isNotValidUpgrad =
    subscriptionDetails?.subscription_period === 'year' &&
    subscriptionDetails?.plan_name === 'Enterprise';

  return (
    <>
      <DefaultLayout title="Accounts and Billings">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="bg-white shadow-lg  pb-20 rounded-lg">
              <p className="text-purpletacx px-6 py-4 text-lg font-semibold border-b border-[#EDEDED]">
                Subscription Overview
              </p>
              <div className="px-6 flex flex-row md:justify-end justify-center mt-5 gap-5 sm:flex-nowrap flex-wrap">
                {!isNotValidUpgrad && (
                  <>
                    <button
                      className={`border text-sm md:text-md rounded-xl px-6 py-2 w-full md:w-auto border-purpletacx text-purpletacx ${
                        isNotValidUpgrad
                          ? ' cursor-not-allowed'
                          : ' cursor-pointer'
                      }`}
                      disabled={isNotValidUpgrad}
                      onClick={() => {
                        navigate('/upgrade-plan');
                      }}
                    >
                      Upgrade Plan
                    </button>
                  </>
                )}
                <button
                  className={`flex flex-row gap-1 md:gap-2 items-center bg-purpletacx text-white justify-center text-sm md:text-md rounded-xl px-6 py-2 w-full md:w-auto`}
                  onClick={handleCancelSubscription}
                  // disabled= {subscriptionDetails?.cancellation == 'canceled'}
                >
                  <span>
                    <MyIcon iconName="cancelSubscription" />
                  </span>
                  Cancel Subscription
                </button>
              </div>
              <div
                className={`grid grid-cols-2 ${subscriptionDetails?.user_limit !== 0 ? 'xl:grid-cols-6' : 'xl:grid-cols-5'} lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3  gap-3 mt-10 px-6`}
              >
                <div className="bg-[#F2F0F1] rounded-xl p-3 drop-shadow-lg">
                  <p className="text-black text-xs md:text-sm">Current Plan</p>
                  <p className="text-black text-md md:text-lg font-bold mt-2">
                    {subscriptionDetails?.plan_name}
                  </p>
                </div>

                {subscriptionDetails?.user_limit !== 0 && (
                  <div className="bg-[#F2F0F1] rounded-xl p-3 drop-shadow-lg">
                    <p className="text-black text-xs md:text-sm">Users</p>
                    <p className="text-black text-md md:text-lg font-bold mt-2">
                      {subscriptionDetails?.user_limit}
                    </p>
                  </div>
                )}

                <div className="bg-[#F2F0F1] rounded-xl p-3 drop-shadow-lg">
                  <p className="text-black text-xs md:text-sm">Billing Cycle</p>
                  <p className="text-black text-md md:text-lg font-bold mt-2">
                    {subscriptionDetails?.subscription_period === 'month'
                      ? 'Monthly'
                      : 'Yearly'}
                  </p>
                </div>

                <div className="bg-[#F2F0F1] rounded-xl p-3 drop-shadow-lg">
                  <p className="text-black text-xs md:text-sm">
                    Recurring Amount
                  </p>
                  <p className="text-black text-md md:text-lg font-bold mt-2">
                    {subscriptionDetails?.currency == 'inr'
                      ? `₹${subscriptionDetails?.amount_total}`
                      : `$${subscriptionDetails?.amount_total}`}
                  </p>
                </div>

                <div className="bg-[#F2F0F1] rounded-xl p-3 drop-shadow-lg">
                  <p className="text-black text-xs md:text-sm">Next Payment</p>
                  <p className="text-black text-md md:text-lg font-bold mt-2">
                    {formatDate(subscriptionDetails?.expire_date)}
                  </p>
                </div>

                <div className="bg-[#F2F0F1] rounded-xl p-3 drop-shadow-lg">
                  <p className="text-black text-xs md:text-sm">
                    Payment method
                  </p>
                  <p className="text-black text-md md:text-lg font-bold mt-2">
                    {capitalizeFirstLetter(
                      subscriptionDetails?.card_brand || '',
                    )}{' '}
                    {`**${subscriptionDetails?.card_digit}`}
                  </p>
                </div>
              </div>
              {/* {subscriptionDetails?.cancellation == 'canceled' && (
                <p className="mt-5 px-6 text-sm text-red-600">
                  Your subscription has already been canceled. The cancellation
                  will take effect after your current billing period ends.
                </p>
              )} */}
            </div>
          </>
        )}

        {cancelVisible && (
          <>
            <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
              <div className="bg-white flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
                <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                  <div className="flex justify-center">
                    <svg
                      className="w-20 h-20 text-red-500 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="68"
                      height="68"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </div>
                  <div className="mt-2 text-center text-black">
                    <p className="font-extrabold text-2xl mb-2">
                      Cancel Subscription
                    </p>
                    {subscriptionDetails?.cancellation == 'canceled' ? (
                      <>
                        <p className="text-sm">
                          Your subscription has already been canceled.
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text-sm">
                          Are you sure you want to cancel your subscription?{' '}
                        </p>
                        <p className="text-sm">
                          This action cannot be undone.{' '}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="gap-2 flex mt-6">
                    {subscriptionDetails?.cancellation == 'canceled' ? (
                      <>
                        <button
                          className="bg-yellowtacx text-black px-10 py-2 rounded-xl"
                          onClick={() => {
                            setCancelVisible(false);
                          }}
                        >
                          Okay
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="bg-yellowtacx text-black px-10 py-2 rounded-xl"
                          onClick={() => {
                            setCancelVisible(false);
                          }}
                        >
                          No
                        </button>
                        <button
                          className="bg-red-700 text-white px-10 py-2 rounded-xl"
                          onClick={handleConfirmCancel}
                        >
                          Yes
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {cancelLoading && <SubLoader />}

        {successVisible && (
          <>
            <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
              <div className="bg-[#594255] flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
                <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                  {errorVisible ? (
                    <MyIcon iconName="crossLarge" />
                  ) : (
                    <MyIcon iconName="success" />
                  )}
                  <div className="mt-8 text-center text-white">
                    <p className="font-extrabold text-2xl">Successful</p>
                    <p className="text-md">{successMessage}</p>
                  </div>
                  <div
                    className="mt-4 px-25 sm:px-35 py-3 rounded-full bg-[#DFC749] text-black font-bold cursor-pointer"
                    // onClick={() => {
                    //   setSuccessVisible(false);
                    //   setSuccessMessage('');
                    //   navigate('/purchase-plan')
                    // }}
                    onClick={handleSuccessCancelation}
                  >
                    Okay
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </DefaultLayout>
    </>
  );
};

export default AccountAndBilling;
