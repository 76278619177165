import React, { ComponentType, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getSubscription, ProfileData } from './api/api';
import Loader from './common/Loader';
import { useAuth } from './AuthContext';
import { useDispatch } from 'react-redux';
import { setSubscription, setUser } from './store/slice/UserSlice';

interface PrivateRouteProps {
  element: ComponentType<any>;
  [key: string]: any; // This allows any other props
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  element: Component,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    // const checkSubscriptionStatus = async () => {
    //   try {
    //     const response = await getSubscription();
    //     setHasSubscription(response?.data.subscription.status === 'active');
    //   } catch (error) {
    //     console.error('Error checking subscription status:', error);
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };
    const checkSubscriptionStatus = async () => {
      try {
        const userData = await ProfileData();
        if (userData?.data.success) {
          dispatch(setUser({ user: userData?.data.user })); // dispatch the user data
          if (userData.data.user.user_role === 'Team mate') {
            setHasSubscription(userData.data.user.user_role === 'Team mate');
          } else {
            const response = await getSubscription();
            setHasSubscription(response?.data.subscription.status === 'active');
            dispatch(setSubscription({ subscription: response?.data.subscription })); // dispatch the sunscription data
          }
        } else {
          logout();
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking subscription status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscriptionStatus();
  }, [logout, navigate, dispatch]);


  if (isLoading) {
    // return <div>Loading...</div>; // Or a loading spinner
    return <Loader />;
  }

  return hasSubscription ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/purchase-plan" />
  );
};

export default PrivateRoute;
