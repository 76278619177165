import { useEffect, useState } from 'react';
import Canvas from '../canvas/Canvas';

const Grid: React.FunctionComponent<{
  id: number | string;
  boxCount: number;
  boxData: any;
  gridColumns: string;
  gridBorderStyle: string;
  boxWidth: string | number;
  boxHeight: string | number;
  boxSpacing: string | number;
  globalStyling: {
    fontColor: string;
    linkColor: string;
    fontSize: string;
    status: boolean;
    ignoreFieldArray: [string];
    globalChangesList: [string];
  };
}> = ({
  id,
  boxCount,
  gridColumns = 'grid-cols-2',
  gridBorderStyle = 'border-dashed',
  boxWidth = 2,
  boxHeight = 2,
  boxSpacing=10,
  boxData = [{ type: 'demo' }],
  globalStyling = {
    fontColor: '#000000',
    linkColor: '#000000',
    fontSize: 'text-lg',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
  },
}) => {


  return (
    <div
      className={`flex flex-row gap-1 p-1 ${gridBorderStyle} border-[1px] pt-4 z-99`}
    >
      <Canvas
        boxData={boxData}
        id={id}
        gridColumns={gridColumns}
        boxWidth={boxWidth}
        boxHeight={boxHeight}
        boxSpacing={boxSpacing}
      />
    </div>
  );
};

export default Grid;
