// ThemeData.js
// Define theme data with components
import Profile from './theme1sComponents/Profile';
import Company from './theme1sComponents/Company';
import Social from './theme1sComponents/Social';
import Carousel from './theme1sComponents/Carousel';
// import logo192 from '../../assets/logo192.jpg';
// import samplebg from './../../assets/samplebg.jpg';
// import samplelogo from './../../assets/sampleLogo.png';
// import syndellLogo from './../../assets/syndellLogo.png';
import Button from './theme1sComponents/Button';
import AboutUs from './theme1sComponents/AboutUs';
import Heading from './theme1sComponents/Heading';
import Testimonial from './theme1sComponents/Testimonial';
import Videos from './theme1sComponents/Videos';
import Divider from './theme1sComponents/Divider';
import SingleImage from './theme1sComponents/SingleImage';
import Grid from './theme1sComponents/GridBlock/block/Grid';
import FlipBox from './theme1sComponents/FlipBox';
const themeData = {
  theme1s: [
    {
      type: 'profile',
      component: Profile,
      componentName: 'Profile',
      displayType: 'block',
      customizationOptions: {
        companyLogo: {
          label: 'Company Logo',
          type: 'image',
          default: '1718108707903-SyndellLogo.png',
          tab: 'content',
        },
        hideCompanyLogo: {
          label: 'Hide Company Logo',
          type: 'boolean',
          default: false,
          tab: 'content',
        },
        backgroundImage: {
          label: 'Background Image',
          type: 'image',
          default: '1718108457839-samplebg.jpg', // Default background image
          tab: 'content',
        },
        fname: {
          label: 'First Name',
          type: 'text',
          default: 'fname',
          tab: 'content',
        },
        lname: {
          label: 'Last Name',
          type: 'text',
          default: 'lname',
          tab: 'content',
        },
        profileImage: {
          label: 'Profile Image',
          type: 'image',
          default: 'profile.jpg',
          tab: 'content',
        },
        companyName: {
          label: 'Company Name',
          type: 'text',
          default: 'Company Name',
          tab: 'content',
        },
        companyNameTextColor: {
          label: 'Company Name Text Color',
          type: 'color',
          default: '#ca8a04',
          tab: 'style',
        },
        jobTitles: {
          label: 'Job Title',
          type: 'dynamicArray',
          default: [],
          tab: 'content',
        },
        fontColor: {
          label: 'Text Color',
          type: 'color',
          default: '#000000',
          tab: 'style',
        },
        linkColor: {
          label: 'QR Text Color',
          type: 'color',
          default: '#000000',
          tab: 'style',
        },
        typography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: 'text-base',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '2',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        companyTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: 'text-base',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          companyTypographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          companyTypographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '1',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        jobTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: 'text-base',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          jobTypographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          jobTypographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '1',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        globalStyling: {
          fontColor: '#000000',
          linkColor: '#000000',
          fontSize: 'text-xl',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
        },
      },
      divider: [
        {
          label: 'Typography',
          value: [
            'backgroundImage',
            'fname',
            'lname',
            'profileImage',
            'fontColor',
            'linkColor',
            'typography',
            'buttonWidth',
          ],
        },
        {
          label: 'Company',
          value: [
            'companyLogo',
            'hideCompanyLogo',
            'companyName',
            'companyNameFontSize',
            'companyNameTextColor',
            'companyTypography',
          ],
        },
        {
          label: 'Job',
          value: ['jobTitles', 'jobTitleFontSize', 'jobTypography'],
        },
      ],
    },
    {
      type: 'Grid',
      component: Grid,
      componentName: 'Grid',
      displayType: 'block',
      boxCount: 2,
      boxData: [{ type: 'demo' }],
      customizationOptions: {
        gridColumns: {
          label: 'Columns',
          type: 'select',
          default: 'grid-cols-2',
          options: [
            { value: 'grid-cols-1', label: 'One' },
            { value: 'grid-cols-2', label: 'Two' },
            { value: 'grid-cols-3', label: 'Three' },
            { value: 'grid-cols-4', label: 'Four' },
          ],
          tab: 'content',
        },
        gridBorderStyle: {
          label: 'Border style',
          type: 'select',
          default: 'border-dashed',
          options: [
            { value: 'border-solid', label: 'Solid' },
            { value: 'border-dashed', label: 'Dashed' },
            { value: 'border-dotted', label: 'Dotted' },
            { value: 'border-none', label: 'None' },
          ],
          tab: 'style',
        },
        globalStyling: {
          fontColor: '#000000',
          linkColor: '#000000',
          fontSize: 'text-xl',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
          font: 'poppins, font-poppins',
        },
        boxWidth: {
          label: 'Width',
          type: 'widthOption',
          default: '0',
          tab: 'content',
        },
        boxHeight: {
          label: 'Height',
          type: 'widthOption',
          default: '0',
          tab: 'content',
        },
        boxSpacing: {
          label: 'Spacing',
          type: 'widthOption',
          default: '10',
          max: '100',
          tab: 'style',
        },
      },
      divider: [
        {
          label: 'Grid',
          value: ['gridColumns', 'gridBorderStyle', 'boxWidth','boxHeight','boxSpacing'],
        },
      ],
    },
    {
      type: 'Image',
      component: SingleImage,
      componentName: 'Image',
      displayType: 'block',
      customizationOptions: {
        image: {
          label: 'Choose image',
          type: 'image',
          default: 'carousel.jpg',
          tab: 'content',
        },
        imageResoluation: {
          label: 'Resoluation',
          type: 'select',
          default: '100%',
          options: [
            { value: '100px', label: 'Thumbnail - 100 x 100' },
            { value: '150px', label: 'Thumbnail - 150 x 150' },
            { value: '300px', label: 'Medium - 300 x 300' },
            { value: '100%', label: 'Full' },
          ],
          tab: 'content',
        },
        hideCaption: {
          label: 'Hide Caption',
          type: 'boolean',
          default: false,
          tab: 'content',
        },
        caption: {
          label: 'Caption',
          type: 'text',
          default: '',
          tab: 'content',
        },
        hideLink: {
          label: 'Hide Link',
          type: 'boolean',
          default: false,
          tab: 'content',
        },
        link: {
          label: 'Link',
          type: 'text',
          default: '',
          tab: 'content',
        },
        imagePosition: {
          label: 'Alignment',
          type: 'alignment',
          default: 'justify-center',
          options: [
            { value: 'justify-start', label: 'Left', icon: 'alignLeft' },
            { value: 'justify-center', label: 'Center', icon: 'alignCenter' },
            { value: 'justify-end', label: 'Right', icon: 'alignRight' },
          ],
          tab: 'style',
        },
        imageTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: 'text-base',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '2',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        imagePadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [10, 10, 10, 10],
          tab: 'style',
        },
        imageMargin: {
          label: 'Margin',
          type: 'sizing',
          inputType: 'Margin',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        globalStyling: {
          fontColor: '#000000',
          linkColor: '#000000',
          fontSize: 'text-lg',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
        },
        imageBoxWidth: {
          label: 'Width',
          type: 'widthOption',
          default: '0',
          tab: 'content',
        },
      },
      divider: [
        {
          label: 'Image',
          value: [
            'image',
            'imageResoluation',
            'caption',
            'hideCaption',
            'link',
            'hideLink',
            'imagePosition',
            'imageTypography',
            'imagePadding',
            'imageMargin',
            'imageBoxWidth',
          ],
        },
      ],
    },
    {
      type: 'social',
      component: Social,
      componentName: 'Social',
      displayType: 'block',
      customizationOptions: {
        links: {
          label: 'Social Icons',
          type: 'links',
          default: [
            {
              name: '',
              url: '',
              iconType: 'local',
              iconName: 'heroicons:plus-16-solid',
              bgColor: '#2563eb',
              fgColor: '#ffffff',
            },
            {
              name: '',
              url: '',
              iconType: 'local',
              iconName: 'heroicons:plus-16-solid',
              bgColor: '#2563eb',
              fgColor: '#ffffff',
            },
            {
              name: '',
              url: '',
              iconType: 'local',
              iconName: 'heroicons:plus-16-solid',
              bgColor: '#2563eb',
              fgColor: '#ffffff',
            },
            {
              name: '',
              url: '',
              iconType: 'local',
              iconName: 'heroicons:plus-16-solid',
              bgColor: '#2563eb',
              fgColor: '#ffffff',
            },
          ],
          tab: 'content',
        },
        linkColor: {
          label: 'Background Color',
          type: 'color',
          default: '#000000',
          tab: 'style',
        },
        linkShape: {
          label: 'Shape',
          type: 'select',
          default: 'rounded',
          options: [
            { value: 'rounded', label: 'Rounded' },
            { value: 'rounded-none', label: 'Square' },
            { value: 'rounded-full', label: 'Circle' },
          ],
          tab: 'content',
        },
        linkAlignment: {
          label: 'Alignment',
          type: 'alignment',
          default: 'justify-center',
          options: [
            { value: 'justify-start', label: 'Left', icon: 'alignLeft' },
            { value: 'justify-center', label: 'Center', icon: 'alignCenter' },
            { value: 'justify-end', label: 'Right', icon: 'alignRight' },
          ],
          tab: 'content',
        },
        linkSpacing: {
          label: 'Spacing',
          type: 'widthOption',
          default: '10',
          max: '100',
          min: '0',
          tab: 'style',
        },
        borderType: {
          label: 'Border Type',
          type: 'select',
          default: 'none',
          options: [
            { value: 'none', label: 'None' },
            { value: 'solid', label: 'Solid' },
            { value: 'dashed', label: 'Dashed' },
            { value: 'dotted', label: 'Dotted' },
            { value: 'double', label: 'Double' },
            { value: 'groove', label: 'Groove' },
          ],
          tab: 'style',
        },
        borderWidth: {
          label: 'Border Width',
          type: 'sizing',
          inputType: 'Width',
          default: [1, 1, 1, 1],
          tab: 'style',
        },
        borderColor: {
          label: 'Border Color',
          type: 'color',
          default: '#000000',
          tab: 'style',
        },
        borderRadius: {
          label: 'Border Radius',
          type: 'sizing',
          inputType: 'Radius',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        globalStyling: {
          fontColor: '#000000',
          linkColor: '#000000',
          fontSize: 'text-xl',
          pmColor: '#ffffff',
          seColor: '#000000',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
        },
      },
      divider: [
        {
          label: 'Social',
          value: [
            'links',
            'linkShape',
            'linkAlignment',
            'linkSpacing',
            'borderType',
            'borderWidth',
            'borderColor',
            'borderRadius',
          ],
        },
        // {
        //   label: 'Border',
        //   value: [ 'borderType', 'borderColor'],
        // },
      ],
    },
    {
      type: 'carousel',
      component: Carousel,
      componentName: 'Carousel',
      displayType: 'block',
      customizationOptions: {
        imagesCarousel: {
          label: 'Images',
          type: 'carousel',
          default: [
            { src: 'carousel.jpg', alt: 'Image 1s' },
            { src: 'carousel.jpg', alt: 'Image 2s' },
          ],
          tab: 'content',
        },
        autoplay: {
          label: 'Autoplay',
          type: 'boolean',
          default: true,
          tab: 'style',
        },
        interval: {
          label: 'Interval (in sec)',
          type: 'number',
          default: 3, // Autoplay interval in milliseconds
          tab: 'style',
        },
        imageResoluation: {
          label: 'Resoluation',
          type: 'select',
          default: '100%',
          options: [
            { value: '100px', label: 'Thumbnail - 100 x 100' },
            { value: '150px', label: 'Thumbnail - 150 x 150' },
            { value: '300px', label: 'Medium - 300 x 300' },
            { value: '100%', label: 'Full' },
          ],
          tab: 'content',
        },
        navigation: {
          label: 'Navigation',
          type: 'select',
          default: 'arrows and dots',
          options: [
            { value: 'arrows and dots', label: 'Arrows and Dots' },
            { value: 'arrows', label: 'Arrows' },
            { value: 'dots', label: 'Dots' },
            { value: 'none', label: 'None' },
          ],
          tab: 'content',
        },
        borderType: {
          label: 'Border Type',
          type: 'select',
          default: 'none',
          options: [
            { value: 'none', label: 'None' },
            { value: 'solid', label: 'Solid' },
            { value: 'double', label: 'Double' },
            { value: 'dotted', label: 'Dotted' },
            { value: 'dashed', label: 'Dashed' },
            { value: 'groove', label: 'Groove' },
          ],
          tab: 'style',
        },
        borderWidth: {
          label: 'Border Width',
          type: 'sizing',
          inputType: 'Width',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        borderRadius: {
          label: 'Border Radius',
          type: 'sizing',
          inputType: 'Radius',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        carouselPadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        carouselMargin: {
          label: 'Margin',
          type: 'sizing',
          inputType: 'Margin',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
      },
      divider: [
        {
          label: 'Carousel',
          value: [
            'imagesCarousel',
            'autoplay',
            'interval',
            'imageResoluation',
            'navigation',
            'borderType',
            'borderWidth',
            'borderRadius',
            'carouselPadding',
            'carouselMargin',
          ],
        },
      ],
    },
    {
      type: 'button',
      component: Button,
      componentName: 'Button',
      displayType: 'block',
      customizationOptions: {
        alignItem: {
          label: 'Position',
          type: 'alignment',
          default: 'center',
          options: [
            { value: 'start', label: 'Left', icon: 'buttonAlignLeft' },
            { value: 'center', label: 'Center', icon: 'buttonAlignCenter' },
            { value: 'end', label: 'Right', icon: 'buttonAlignRight' },
          ],
          tab: 'style',
        },
        name: {
          label: 'Name',
          type: 'text',
          default: 'About me',
          tab: 'content',
        },
        url: {
          label: 'URL (Link)',
          type: 'text',
          default: 'http://example.com/1',
          tab: 'content',
        },
        backgroundColor: {
          label: 'Background Color',
          type: 'color',
          default: '#1d4ed8',
          tab: 'style',
        },
        fontColor: {
          label: 'Font Color',
          type: 'color',
          default: '#fff',
          tab: 'style',
        },
        full_width: {
          label: 'Resizable width',
          type: 'boolean',
          default: false,
          tab: 'style',
        },
        buttonTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: 'text-base',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
        },
        buttonPadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [10, 10, 10, 10],
          tab: 'style',
        },
        buttonMargin: {
          label: 'Margin',
          type: 'sizing',
          inputType: 'Margin',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        buttonRadius: {
          label: 'Border Radius',
          type: 'sizing',
          inputType: 'Radius',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        globalStyling: {
          fontColor: '#fff',
          linkColor: '#000000',
          fontSize: 'text-xl',
          groundColor: '#000000',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
          buttonPadding: [10, 10, 10, 10],
          buttonMargin: [0, 0, 0, 0],
          buttonRadius: [10, 10, 10, 10],
        },
      },
      divider: [
        {
          label: 'Button',
          value: [
            'name',
            'url',
            'alignItem',
            'full_width',
            'buttonPadding',
            'buttonMargin',
            'buttonRadius',
            'fontColor',
            'backgroundColor',
            'buttonTypography',
          ],
        },
      ],
    },
    {
      type: 'aboutus',
      component: AboutUs,
      componentName: 'About Us',
      displayType: 'block',
      customizationOptions: {
        textContent: {
          label: 'About us',
          type: 'textarea',
          default: 'Welcome to our website! We are glad to have you here.',
          tab: 'content',
        },
        hideText: {
          label: 'Hide Text',
          type: 'boolean',
          default: true,
          tab: 'content',
        },
        fontColor: {
          label: 'Font Color',
          type: 'color',
          default: '#594255',
          tab: 'style',
        },
        aboutUsTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: 'text-base',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '2',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        aboutUsPadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [10, 10, 10, 10],
          tab: 'style',
        },
        aboutUsMargin: {
          label: 'Margin',
          type: 'sizing',
          inputType: 'Margin',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        globalStyling: {
          fontColor: '#000000',
          linkColor: '#000000',
          fontSize: 'text-xl',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
        },
      },
      divider: [
        {
          label: 'About Us',
          value: [
            'textContent',
            'hideText',
            'fontColor',
            'aboutUsTypography',
            'aboutUsPadding',
            'aboutUsMargin',
          ],
        },
      ],
    },
    {
      type: 'heading',
      component: Heading,
      componentName: 'Heading',
      displayType: 'block',
      customizationOptions: {
        textContent: {
          label: 'Heading',
          type: 'dynamicText',
          default: 'TACX',
          tab: 'content',
        },
        textPosition: {
          label: 'Alignment',
          type: 'alignment',
          default: 'text-center',
          options: [
            { value: 'text-left', label: 'Left', icon: 'alignLeft' },
            { value: 'text-center', label: 'Center', icon: 'alignCenter' },
            { value: 'text-right', label: 'Right', icon: 'alignRight' },
            { value: 'text-justify', label: 'Justify', icon: 'alignJustify' },
          ],
          tab: 'style',
        },
        hideText: {
          label: 'Hide Heading',
          type: 'boolean',
          default: true,
          tab: 'content',
        },
        fontColor: {
          label: 'Text Color',
          type: 'color',
          default: '#594255',
          tab: 'style',
        },
        headingTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: 'text-base',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '2',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        headingPadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        headingMargin: {
          label: 'Margin',
          type: 'sizing',
          inputType: 'Margin',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        globalStyling: {
          fontColor: '#000000',
          linkColor: '#000000',
          fontSize: 'text-xl',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
        },
      },
      divider: [
        {
          label: 'Heading',
          value: [
            'textPosition',
            'textContent',
            'hideText',
            'textFontSize',
            'fontWeight',
            'fontColor',
            'headingTypography',
            'headingPadding',
            'headingMargin',
            'userDetailSelect',
          ],
        },
      ],
    },
    {
      type: 'testimonial',
      component: Testimonial,
      componentName: 'Testimonial',
      displayType: 'block',
      customizationOptions: {
        Testimonial: {
          // label: 'Testimonial',
          type: 'multiArray',
          default: [
            {
              comment: 'Welcome to our website! We are glad to have you here.',
              name: 'Name',
            },
          ],
          tab: 'content',
        },
        fontColor: {
          label: 'Font Color',
          type: 'color',
          default: '#ffff',
          tab: 'style',
        },
        namefontColor: {
          label: 'Name Font Color',
          type: 'colorTwo',
          default: '#594255',
          tab: 'style',
        },
        testimonialTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: 'text-base',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
        },
        testimonialPadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        testimonialMargin: {
          label: 'Margin',
          type: 'sizing',
          inputType: 'Margin',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        globalStyling: {
          fontColor: '#000000',
          linkColor: '#000000',
          fontSize: 'text-xl',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
        },
      },
      divider: [
        {
          label: 'Testimonial',
          value: [
            'Testimonial',
            'fontColor',
            'namefontColor',
            'testimonialTypography',
            'testimonialPadding',
            'testimonialMargin',
          ],
        },
      ],
    },
    {
      type: 'videos',
      component: Videos,
      componentName: 'Videos',
      displayType: 'block',
      customizationOptions: {
        videoUrls: {
          label: 'Video URL',
          type: 'dynamicArray',
          default: ['https://youtu.be/MLpWrANjFbI?si=dnjJcqSuN7kvg9y5'],
          tab: 'content',
        },
        videosPadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        videosMargin: {
          label: 'Margin',
          type: 'sizing',
          inputType: 'Margin',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
      },
      divider: [
        {
          label: 'Video',
          value: ['videoUrls', 'videosPadding', 'videosMargin'],
        },
      ],
    },
    {
      type: 'divider',
      component: Divider,
      componentName: 'Divider',
      displayType: 'block',
      customizationOptions: {
        dividerBorderStyle: {
          label: 'Style',
          type: 'select',
          default: 'border-solid',
          options: [
            { value: 'border-solid', label: 'Solid' },
            { value: 'border-dashed', label: 'Dashed' },
            { value: 'border-dotted', label: 'Dotted' },
          ],
          tab: 'content',
        },
        dividerWidth: {
          label: 'Width',
          type: 'widthOption',
          default: '100',
          max: '100',
          min: '0',
          tab: 'content',
        },
        dividerPosition: {
          label: 'Alignment',
          type: 'alignment',
          default: 'justify-start',
          options: [
            { value: 'justify-start', label: 'Left', icon: 'alignLeft' },
            { value: 'justify-center', label: 'Center', icon: 'alignCenter' },
            { value: 'justify-end', label: 'Right', icon: 'alignRight' },
          ],
          tab: 'content',
        },
        icon: {
          label: 'Add Element',
          type: 'icon',
          default: [
            {
              name: 'icon',
              iconType: 'local',
              iconName: 'mingcute:group-3-fill',
              fgColor: '#000000',
              bgColor: '#2563eb00',
              show: 'none',
            },
          ],
          tab: 'content',
        },
        borderColor: {
          label: 'Border Color',
          type: 'color',
          default: 'black',
          tab: 'style',
        },
        globalStyling: {
          pmColor: '#ffffff',
          seColor: '#000000',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
        },
      },
      divider: [
        {
          label: 'Divider',
          value: [
            'dividerBorderStyle',
            'dividerPosition',
            'dividerWidth',
            // 'dividerElement',
            'icon',
            'borderColor',
          ],
        },
      ],
    },
    {
      type: 'company',
      component: Company,
      componentName: 'Company',
      displayType: 'block',
      customizationOptions: {
        companyName: {
          label: 'Company Name',
          type: 'text',
          default: 'Company name',
          tab: 'content',
        },
        role: {
          label: 'Role',
          type: 'text',
          default: 'developer',
          tab: 'content',
        },
        CompanyLogo: {
          label: 'Company Logo',
          type: 'image',
          default: 'profile.jpg',
          tab: 'content',
        },
        layout: {
          label: 'Layout',
          type: 'select',
          default: 'flex-col',
          options: [
            { value: 'flex-col', label: 'Next by Next' },
            { value: 'side-by-side', label: 'Side by Side' },
          ],
          tab: 'style',
        },
        textColor: {
          label: 'Text Color',
          type: 'color',
          default: '#000000',
          tab: 'style',
        },
        companyPadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        companyMargin: {
          label: 'Margin',
          type: 'sizing',
          inputType: 'Margin',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        globalStyling: {
          fontColor: '#000000',
          linkColor: '#000000',
          fontSize: 'text-xl',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
        },
      },
      divider: [
        {
          label: 'Company',
          value: [
            'companyName',
            'role',
            'CompanyLogo',
            'layout',
            'textColor',
            'companyPadding',
            'companyMargin',
          ],
        },
      ],
    },
    // {
    //   type: 'Grid',
    //   component: Grid,
    //   componentName: 'Grid',
    //   displayType: 'block',
    //   boxCount: 2,
    //   boxData: [{ type: 'demo' }],
    //   customizationOptions: {
    //     gridColumns: {
    //       label: 'Columns',
    //       type: 'select',
    //       default: 'grid-cols-2',
    //       options: [
    //         { value: 'grid-cols-1', label: 'One' },
    //         { value: 'grid-cols-2', label: 'Two' },
    //         { value: 'grid-cols-3', label: 'Three' },
    //         { value: 'grid-cols-4', label: 'Four' },
    //       ],
    //       tab: 'content',
    //     },
    //     gridBorderStyle: {
    //       label: 'Border style',
    //       type: 'select',
    //       default: 'border-dashed',
    //       options: [
    //         { value: 'border-solid', label: 'Solid' },
    //         { value: 'border-dashed', label: 'Dashed' },
    //         { value: 'border-dotted', label: 'Dotted' },
    //         { value: 'border-none', label: 'None' },
    //       ],
    //       tab: 'style',
    //     },
    //     globalStyling: {
    //       fontColor: '#000000',
    //       linkColor: '#000000',
    //       fontSize: 'text-xl',
    //       status: false,
    //       ignoreFieldArray: [],
    //       globalChangesList: [],
    //       font: 'poppins, font-poppins',
    //     },
    //   },
    //   divider: [
    //     {
    //       label: 'Grid',
    //       value: ['gridColumns', 'gridBorderStyle'],
    //     },
    //   ],
    // },
    {
      type: 'Flip box',
      component: FlipBox,
      componentName: 'Flip box',
      displayType: 'block',
      customizationOptions: {
        frontBackgroundColor: {
          label: 'Background Color',
          type: 'color',
          default: '#DED9DD',
          tab: 'content',
        },
        icon: {
          label: 'Add Element',
          type: 'icon',
          default: [
            {
              name: 'icon',
              iconType: 'local',
              iconName: 'mingcute:group-3-fill',
              fgColor: '#000000',
              bgColor: '#2563eb00',
              show: 'none',
            },
          ],
          tab: 'content',
        },
        frontTitle: {
          label: 'Title',
          type: 'text',
          default: 'This is the heading',
          tab: 'content',
        },
        frontDescription: {
          label: 'Description',
          type: 'textarea',
          default: 'Welcome to our website! We are glad to have you here.',
          tab: 'content',
        },
        // frontImageResoluation: {
        //   label: 'Image Resoluation',
        //   type: 'select',
        //   default: '100%',
        //   options: [
        //     { value: '100px', label: 'Thumbnail - 100 x 100' },
        //     { value: '150px', label: 'Thumbnail - 150 x 150' },
        //     { value: '300px', label: 'Medium - 300 x 300' },
        //     { value: '100%', label: 'Full' },
        //   ],
        //   tab: 'content',
        // },
        backBackgroundColor: {
          label: 'Background Color',
          type: 'color',
          default: '#dfc749',
          tab: 'content',
        },
        backTitle: {
          label: 'Title',
          type: 'text',
          default: 'This is the heading',
          tab: 'content',
        },
        backDescription: {
          label: 'Description',
          type: 'textarea',
          default: 'Welcome to our website! We are glad to have you here.',
          tab: 'content',
        },
        buttonText: {
          label: 'Button text',
          type: 'text',
          default: 'Click Here',
          tab: 'content',
        },
        buttonUrl: {
          label: 'Link',
          type: 'text',
          default: 'http://example.com/1',
          tab: 'content',
        },
        flipInterval: {
          label: 'Flip interval',
          type: 'number',
          default: 3, // Autoplay interval in milliseconds
          tab: 'style',
        },
        flipBoxHeight: {
          label: 'Height',
          type: 'widthOption',
          default: '200',
          max: '1000',
          min: '100',
          tab: 'content',
        },
        borderRadius: {
          label: 'Border Radius',
          type: 'widthOption',
          default: '0',
          max: '100',
          min: '0',
          tab: 'content',
        },
        frontPadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        frontAlignment: {
          label: 'Alignment',
          type: 'alignment',
          default: 'justify-center',
          options: [
            { value: 'justify-start', label: 'Left', icon: 'buttonAlignTop' },
            {
              value: 'justify-center',
              label: 'Center',
              icon: 'buttonAlignCenter',
            },
            { value: 'justify-end', label: 'Right', icon: 'buttonAlignBottom' },
          ],
          tab: 'style',
        },
        frontPosition: {
          label: 'Vertical Position',
          type: 'alignment',
          default: 'center',
          options: [
            { value: 'start', label: 'Left', icon: 'alignLeft' },
            { value: 'center', label: 'Center', icon: 'alignCenter' },
            { value: 'end', label: 'Right', icon: 'alignRight' },
          ],
          tab: 'style',
        },
        frontIconHr: {
          type: 'hrTag',
          tab: 'style',
        },
        frontIconHeading: {
          label: 'Icon',
          type: 'heading',
          tab: 'style',
        },
        frontIconSpacing: {
          label: 'Spacing',
          type: 'widthOption',
          default: '0',
          max: '100',
          min: '0',
          tab: 'style',
        },
        frontIconSize: {
          label: 'Icon Size',
          type: 'widthOption',
          default: '60',
          max: '100',
          min: '0',
          tab: 'style',
        },
        frontIconRotate: {
          label: 'Icon Rotate',
          type: 'widthOption',
          default: '0',
          max: '360',
          min: '0',
          tab: 'style',
        },
        frontTextHr: {
          type: 'hrTag',
          tab: 'style',
        },
        frontTextHeading: {
          label: 'Title',
          type: 'heading',
          tab: 'style',
        },
        frontTitleSpacing: {
          label: 'Spacing',
          type: 'widthOption',
          default: '0',
          max: '100',
          min: '0',
          tab: 'style',
        },
        frontTitleTextColor: {
          label: 'Text Color',
          type: 'color',
          default: '#594255',
          tab: 'style',
        },
        frontTextTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: '16px',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '2',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        frontDescriptionHr: {
          type: 'hrTag',
          tab: 'style',
        },
        frontDescriptionHeading: {
          label: 'Description',
          type: 'heading',
          tab: 'style',
        },
        frontDescriptionTextColor: {
          label: 'Text Color',
          type: 'color',
          default: '#594255',
          tab: 'style',
        },
        frontDescriptionTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: '14px',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '2',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        backPadding: {
          label: 'Padding',
          type: 'sizing',
          inputType: 'Padding',
          default: [0, 0, 0, 0],
          tab: 'style',
        },
        backAlignment: {
          label: 'Alignment',
          type: 'alignment',
          default: 'justify-center',
          options: [
            { value: 'justify-start', label: 'Left', icon: 'buttonAlignTop' },
            {
              value: 'justify-center',
              label: 'Center',
              icon: 'buttonAlignCenter',
            },
            { value: 'justify-end', label: 'Right', icon: 'buttonAlignBottom' },
          ],
          tab: 'style',
        },
        backPosition: {
          label: 'Vertical Position',
          type: 'alignment',
          default: 'center',
          options: [
            { value: 'start', label: 'Left', icon: 'alignLeft' },
            { value: 'center', label: 'Center', icon: 'alignCenter' },
            { value: 'end', label: 'Right', icon: 'alignRight' },
          ],
          tab: 'style',
        },
        backTextHr: {
          type: 'hrTag',
          tab: 'style',
        },
        backTextHeading: {
          label: 'Title',
          type: 'heading',
          tab: 'style',
        },
        backTitleSpacing: {
          label: 'Spacing',
          type: 'widthOption',
          default: '0',
          max: '100',
          min: '0',
          tab: 'style',
        },
        backTitleTextColor: {
          label: 'Text Color',
          type: 'color',
          default: '#594255',
          tab: 'style',
        },
        backTextTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: '16px',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '2',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        backDescriptionHr: {
          type: 'hrTag',
          tab: 'style',
        },
        backDescriptionHeading: {
          label: 'Description',
          type: 'heading',
          tab: 'style',
        },
        backDescriptionTextColor: {
          label: 'Text Color',
          type: 'color',
          default: '#594255',
          tab: 'style',
        },
        backDescriptionSpacing: {
          label: 'Spacing',
          type: 'widthOption',
          default: '0',
          max: '100',
          min: '0',
          tab: 'style',
        },
        backDescriptionTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: '14px',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '2',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        backButtonHr: {
          type: 'hrTag',
          tab: 'style',
        },
        backButtonHeading: {
          label: 'Button',
          type: 'heading',
          tab: 'style',
        },
        backButtonSize: {
          label: 'Size',
          type: 'select',
          default: 'px-4 py-2',
          options: [
            { value: 'px-2 py-0', label: 'Extra Small' },
            { value: 'px-4 py-2', label: 'Small' },
            { value: 'px-6 py-4', label: 'Medium' },
            { value: 'px-8 py-6', label: 'Large' },
            // { value: 'px-10 py-8', label: 'Extra Large' },
          ],
          tab: 'style',
        },
        backButtonTypography: {
          type: 'editor',
          family: {
            label: 'Font Family',
            type: 'select',
            default: 'poppins, font-poppins',
            options: [
              {
                key: 1,
                value: 'Abril Fatface , font-abrilfatface',
                label: 'Abril Fatface',
              },
              { key: 2, value: 'Cinzel , font-cinzel', label: 'Cinzel' },
              { key: 3, value: 'monospace, font-mono', label: 'Mono' },
              { key: 4, value: 'poppins, font-poppins', label: 'Poppins' },
              { key: 5, value: 'sans-serif, font-sans', label: 'Sans' },
              { key: 6, value: 'Satisfy , font-satisfy', label: 'Satisfy' },
              { key: 7, value: 'Satoshi, font-satoshi', label: 'Satoshi' },
              { key: 8, value: 'serif, font-serif', label: 'Serif' },
            ],
            tab: 'style',
          },
          nameFontSize: {
            label: 'Size',
            type: 'select',
            default: '14px',
            options: [
              { value: '1px', label: '1px' },
              { value: '2px', label: '2px' },
              { value: '4px', label: '4px' },
              { value: '6px', label: '6px' },
              { value: '8px', label: '8px' },
              { value: '10px', label: '10px' },
              { value: '14px', label: '14px' },
              { value: '16px', label: '16px' },
              { value: '18px', label: '18px' },
              { value: '20px', label: '20px' },
              { value: '24px', label: '24px' },
              { value: '30px', label: '30px' },
            ],
            tab: 'style',
          },
          typographyWeight: {
            label: 'Font Weight',
            type: 'select',
            default: 'font-[600]',
            options: [
              { value: 'font-[200]', label: '200 (Extra Light)' },
              { value: 'font-[300]', label: '300 (Light)' },
              { value: 'font-[400]', label: '400 (Normal)' },
              { value: 'font-[500]', label: '500 (Medium (Recommended))' },
              { value: 'font-[600]', label: '600 (Semi Bold)' },
              { value: 'font-[700]', label: '700 (Bold)' },
              { value: 'font-[800]', label: '800 (Extra Bold)' },
            ],
            tab: 'style',
          },
          typographyStyle: {
            label: 'Style',
            type: 'select',
            default: 'normal',
            options: [
              { value: 'italic', label: 'Italic' },
              { value: 'normal', label: 'Normal' },
              { value: 'oblique', label: 'Oblique' },
            ],
            tab: 'style',
          },
          lineHeight: {
            label: 'Line Height',
            type: 'widthOption',
            default: '2',
            max: '100',
            min: '0',
            tab: 'content',
          },
        },
        backButtonfontColor: {
          label: 'Text Color',
          type: 'color',
          default: '#594255',
          tab: 'style',
        },
        backButtonBackgroundColor: {
          label: 'Background Color',
          type: 'color',
          default: '#ffff',
          tab: 'style',
        },
        backButtonBorderColor: {
          label: 'Border Color',
          type: 'color',
          default: '#594255',
          tab: 'style',
        },
        backButtonBorderWidth: {
          label: 'Border Width',
          type: 'widthOption',
          default: '1',
          max: '10',
          min: '0',
          tab: 'style',
        },
        backButtonBorderRadius: {
          label: 'Border Radius',
          type: 'widthOption',
          default: '0',
          max: '100',
          min: '0',
          tab: 'style',
        },
        globalStyling: {
          fontColor: '#594255',
          textColor: '#594255',
          linkColor: '#000000',
          fontSize: 'text-xl',
          groundColor: '#ffff',
          status: false,
          ignoreFieldArray: [],
          globalChangesList: [],
          font: 'poppins, font-poppins',
        },
      },
      divider: [
        {
          label: 'Front',
          value: [
            'frontBackgroundColor',
            'icon',
            'frontTitle',
            'frontDescription',
            'frontBackground',
            'frontPadding',
            'frontAlignment',
            'frontPosition',
            'frontIconHr',
            'frontIconHeading',
            'frontIconSpacing',
            'frontIconSize',
            'frontIconRotate',
            'frontTextHr',
            'frontTextHeading',
            'frontTitleSpacing',
            'frontTitleTextColor',
            'frontTextTypography',
            'frontDescriptionHr',
            'frontDescriptionHeading',
            'frontDescriptionTextColor',
            'frontDescriptionTypography',
          ],
          // type :"content"
        },
        {
          label: 'Back',
          value: [
            'backBackgroundColor',
            'backTitle',
            'backDescription',
            'buttonText',
            'buttonUrl',
            'backPadding',
            'backAlignment',
            'backPosition',
            'backTextHr',
            'backTextHeading',
            'backTitleSpacing',
            'backTitleTextColor',
            'backTextTypography',
            'backDescriptionHr',
            'backDescriptionHeading',
            'backDescriptionTextColor',
            'backDescriptionSpacing',
            'backDescriptionTypography',
            'backButtonHr',
            'backButtonHeading',
            'backButtonSize',
            'backButtonTypography',
            'backButtonfontColor',
            'backButtonBackgroundColor',
            'backButtonBorderColor',
            'backButtonBorderWidth',
            'backButtonBorderRadius',
          ],
          // type :"content"
        },
        {
          label: 'Settings',
          value: ['flipBoxHeight', 'borderRadius', 'flipInterval'],
        },
        // {
        //   label: 'Front style',
        //   value: ['flipInterval'],
        //   type :"style"
        // },
      ],
    },
    // Add more components as needed
  ],
  // Add more themes as needed
};

export default themeData;
