// MyIcon.js
import React from 'react';
import icons from './IconLibrary';

type MyIconProps = {
  iconName: string;
  color?: string;
};

const MyIcon: React.FC<MyIconProps> = ({ iconName, color = 'white' }) => {
  const IconComponent = icons[iconName];
  
  if (!IconComponent) {
    console.error(`Icon "${iconName}" not found.`);
    return null;
  }

  return IconComponent(color);
};

export default MyIcon;
