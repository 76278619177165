import React, { useEffect, useState } from 'react';
import CardDataStats from '../../components/CardDataStats';
import ChartThree from '../../components/Charts/ChartThree';
import ChartTwo from '../../components/Charts/ChartTwo';
import DefaultLayout from '../../layout/DefaultLayout';
import MyIcon from '../../components/CardEditor/icons/MyIcon';
import { getCardEngagementData } from '../../api/api';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slice/UserSlice';
import Loader from '../../common/Loader';

interface EngagementDataProps {
  pageView: number;
  connection: number;
  viewPerUser: any;
  engagementTime: any;
}

const Analytics: React.FC = () => {
  const userDetails = useSelector(selectUser);
  const userData = userDetails?.user;
  const [loading, setLoading] = useState<boolean>(false);
  const [engagementData, setEngagementData] = useState<EngagementDataProps>();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (userData.id && userData.id !== null) {
      CardEngagementData(userData.id);
    }
  }, [userData]);

  const CardEngagementData = async (userId: any) => {
    try {
      setLoading(true);
      const response = await getCardEngagementData(userId);
      setChartData(response.data);

      // Initialize metrics
      let pageView = 0;
      let totalEngagementTime = 0;
      let totalViews = 0;

      // Process response
      response.data.forEach((data: any) => {
        pageView += data.view_count;
        totalEngagementTime += data.engagement_time;
        totalViews += 1; // Count each visitor
      });

      // Calculate metrics
      const viewPerUser =
        totalViews > 0 ? (pageView / totalViews).toFixed(2) : 0;
      const engagementTime =
        totalViews > 0 ? (totalEngagementTime / totalViews).toFixed(2) : 0;
      const connection = new Set(
        response.data.map((entry: any) => entry.visitor_id),
      ).size;

      // // Example dynamic rate calculation (replace with your actual calculation logic)
      // const previousPageView = 100; // Replace with your previous period's data
      // const previousConnection = 20; // Replace with your previous period's data
      // const previousViewPerUser = 1.5; // Replace with your previous period's data
      // const previousEngagementTime = 10000; // Replace with your previous period's data

      // const pageViewRate = previousPageView > 0 ? (((pageView - previousPageView) / previousPageView) * 100).toFixed(2) + '%' : '0%';
      // const connectionRate = previousConnection > 0 ? (((connection - previousConnection) / previousConnection) * 100).toFixed(2) + '%' : '0%';
      // const viewPerUserRate = previousViewPerUser > 0 ? (((viewPerUser - previousViewPerUser) / previousViewPerUser) * 100).toFixed(2) + '%' : '0%';
      // const engagementTimeRate = previousEngagementTime > 0 ? (((engagementTime - previousEngagementTime) / previousEngagementTime) * 100).toFixed(2) + '%' : '0%';

      // Set state with calculated values
      setEngagementData({
        pageView,
        connection,
        viewPerUser,
        engagementTime,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error while retriving card engagement data:', error);
    }
  };
  const formatTime = (seconds: any) => {
    // const seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    let timeString = '';
    if (hours > 0) {
      timeString += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
      timeString += `${minutes}m `;
    }
    if (hours === 0) {
      timeString += `${remainingSeconds}s`;
    }

    return timeString.trim();
  };
  const engagementTimeFormate = formatTime(engagementData?.engagementTime);
  return (
    <DefaultLayout title="Analytics">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
            <CardDataStats
              title="Page view"
              total={engagementData?.pageView}
              rate="0.43%"
              levelUp
            >
              <MyIcon iconName="pageView" />
            </CardDataStats>
            <CardDataStats
              title="Connections"
              total={engagementData?.connection}
              rate="4.35%"
              levelUp
            >
              <MyIcon iconName="connection" />
            </CardDataStats>
            <CardDataStats
              title="View Per User"
              total={engagementData?.viewPerUser}
              rate="2.59%"
              levelUp
            >
              <MyIcon iconName="viewPerUser" />
            </CardDataStats>
            <CardDataStats
              title="Engagement Time"
              total={engagementTimeFormate}
              rate="0.95%"
              levelDown
            >
              <MyIcon iconName="engagementTime" />
            </CardDataStats>
          </div>

          <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
            <ChartTwo chartData={chartData} />
            <ChartThree chartData={chartData} />
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default Analytics;
