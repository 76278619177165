// store.tsx
import { configureStore } from '@reduxjs/toolkit';
import userReducer, { selectedItemReducer } from './slice/UserSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    selectedItem: selectedItemReducer,
  },
});

export default store;
