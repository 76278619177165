import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import defaultImage1 from '../../../assets/carousel-1.svg';
import defaultImage2 from '../../../assets/carousel-2.svg';
import defaultImage3 from '../../../assets/carousel-3.svg';
import defaultImage4 from '../../../assets/carousel-4.svg';
import { useEffect, useState } from 'react';

const Carousel = ({
  images = [
    { src: 'carousel.jpg', alt: 'Image 1s' },
    { src: 'carousel.jpg', alt: 'Image 2s' },
  ],
  autoplay = true,
  interval = 3, // Default to 3 seconds if not provided
  imageResoluation = '100%',
  navigation = 'arrows and dots',
  borderType = 'none',
  borderWidth = [0, 0, 0, 0],
  borderRadius = [0, 0, 0, 0],
  carouselPadding = [0, 0, 0, 0],
  carouselMargin = [0, 0, 0, 0],
}) => {
  const autoSlideInterval = interval * 1000; // Convert interval from seconds to milliseconds
  const api_url = import.meta.env.VITE_API_URL;
  const [showArrows, setShowArrows] = useState(true);
  const [showDots, setShowDots] = useState(true);

  useEffect(() => {

    switch (navigation) {
      case 'arrows and dots':
        setShowArrows(true);
        setShowDots(true);
        break;
      case 'arrows':
        setShowArrows(true);
        setShowDots(false);
        break;
      case 'dots':
        setShowArrows(false);
        setShowDots(true);
        break;
      case 'none':
        setShowArrows(false);
        setShowDots(false);
        break;
      default:
        setShowArrows(true);
        setShowDots(true);
        break;
    }
  }, [navigation]);
  return (
    <div className="relative w-full p-2">
      <div
        style={{
          padding: `${carouselPadding?.[0]}px ${carouselPadding?.[1]}px ${carouselPadding?.[2]}px ${carouselPadding?.[3]}px`,
          margin: `${carouselMargin?.[0]}px ${carouselMargin?.[1]}px ${carouselMargin?.[2]}px ${carouselMargin?.[3]}px`,
        }}
      >
        <ResponsiveCarousel
          showArrows={showArrows}
          showIndicators={showDots}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={autoplay}
          interval={autoSlideInterval}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={`${api_url}/public/cards/images/${image.src}`}
                alt={image.alt || `carousel-${index + 1}`}
                className="block object-cover"
                style={{
                  width: imageResoluation,
                  height: imageResoluation,
                  borderWidth: `${borderWidth?.[0]}px ${borderWidth?.[1]}px ${borderWidth?.[2]}px ${borderWidth?.[3]}px`,
                  borderStyle: borderType,
                  borderRadius: `${borderRadius?.[0]}px ${borderRadius?.[1]}px ${borderRadius?.[2]}px ${borderRadius?.[3]}px`,
                }}
              />
            </div>
          ))}
        </ResponsiveCarousel>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  ).isRequired,
  autoplay: PropTypes.bool,
  interval: PropTypes.number, // Interval in seconds
};

Carousel.defaultProps = {
  images: [
    { src: defaultImage1, alt: 'Default Image 1' },
    { src: defaultImage2, alt: 'Default Image 2' },
    { src: defaultImage3, alt: 'Default Image 3' },
    { src: defaultImage4, alt: 'Default Image 4' },
  ],
  autoplay: true,
  interval: 3, // Default interval of 3 seconds
};

export default Carousel;

// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import defaultImage1 from "../../../assets/carousel-1.svg";
// import defaultImage2 from "../../../assets/carousel-2.svg";
// import defaultImage3 from "../../../assets/carousel-3.svg";
// import defaultImage4 from "../../../assets/carousel-4.svg";

// const Carousel = ({
//   images = [
//     { src: '1718185736924-carousel-3.svg', alt: 'Image 1s' },
//     { src: '1718185853659-carousel-1.svg', alt: 'Image 2s' },
//   ],
//   autoplay,
//   interval = 3,
// }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const autoSlideInterval = interval * 1000 || 3000; // Default to 3 seconds if not provided

//   const handlePrev = () => {
//     const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
//     setCurrentIndex(newIndex);
//   };

//   const handleNext = () => {
//     const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//   };

//   const handleIndicatorClick = (index) => {
//     setCurrentIndex(index);
//   };

//   useEffect(() => {
//     if (autoplay) {
//       const interval = setInterval(handleNext, autoSlideInterval);
//       return () => clearInterval(interval); // Clear the interval on component unmount
//     }
//   }, [currentIndex, autoSlideInterval, autoplay]);

//   return (
//     <div
//       id="default-carousel"
//       className="relative w-full p-2"
//       data-carousel="slide"
//     >
//       {/* Carousel wrapper */}
//       <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
//         {images.map((image, index) => (
//           <div
//             key={index}
//             className={`absolute block w-full h-full transition-opacity duration-700 ease-in-out ${
//               index === currentIndex ? 'opacity-100' : 'opacity-0'
//             }`}
//             data-carousel-item
//           >
//             <img
//               src={`http://localhost:4000/public/cards/images/${image.src}`}
//               className="block w-full h-full object-cover"
//               alt={image.alt || `carousel-${index + 1}`}
//             />
//           </div>
//         ))}
//       </div>

//       {/* Slider indicators */}
//       <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
//         {images.map((_, index) => (
//           <button
//             key={index}
//             type="button"
//             className={`w-3 h-3 rounded-full ${
//               index === currentIndex ? 'bg-blue-600' : 'bg-white'
//             }`}
//             aria-current={index === currentIndex}
//             aria-label={`Slide ${index + 1}`}
//             onClick={() => handleIndicatorClick(index)}
//           ></button>
//         ))}
//       </div>

//       {/* Slider controls */}
//       <button
//         type="button"
//         className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//         onClick={handlePrev}
//         data-carousel-prev
//       >
//         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//           <svg
//             className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//             aria-hidden="true"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 6 10"
//           >
//             <path
//               stroke="currentColor"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="M5 1 1 5l4 4"
//             />
//           </svg>
//           <span className="sr-only">Previous</span>
//         </span>
//       </button>
//       <button
//         type="button"
//         className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//         onClick={handleNext}
//         data-carousel-next
//       >
//         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//           <svg
//             className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//             aria-hidden="true"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 6 10"
//           >
//             <path
//               stroke="currentColor"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="m1 9 4-4-4-4"
//             />
//           </svg>
//           <span className="sr-only">Next</span>
//         </span>
//       </button>
//     </div>
//   );
// };

// Carousel.propTypes = {
//   images: PropTypes.arrayOf(
//     PropTypes.shape({
//       src: PropTypes.string.isRequired,
//       alt: PropTypes.string
//     })
//   ).isRequired,
//   autoplay: PropTypes.bool,
//   interval: PropTypes.number
// };

// Carousel.defaultProps = {
//   images: [
//     { src: defaultImage1, alt: 'Default Image 1' },
//     { src: defaultImage2, alt: 'Default Image 2' },
//     { src: defaultImage3, alt: 'Default Image 3' },
//     { src: defaultImage4, alt: 'Default Image 4' }
//   ],
//   autoplay: true,
//   interval: 3000
// };

// export default Carousel;

// // import React, { useState, useEffect } from 'react';
// // import carousel1 from "../../assets/carousel-1.svg";
// // import carousel2 from "../../assets/carousel-2.svg";
// // import carousel3 from "../../assets/carousel-3.svg";
// // import carousel4 from "../../assets/carousel-4.svg";
// // import carousel5 from "../../assets/carousel-5.svg";

// // const images = [carousel1, carousel2, carousel3, carousel4, carousel5];

// // const Carousel = () => {
// //   const [currentIndex, setCurrentIndex] = useState(0);
// //   const autoSlideInterval = 3000; // 3 seconds

// //   const handlePrev = () => {
// //     const newIndex = (currentIndex === 0) ? images.length - 1 : currentIndex - 1;
// //     setCurrentIndex(newIndex);
// //   };

// //   const handleNext = () => {
// //     const newIndex = (currentIndex === images.length - 1) ? 0 : currentIndex + 1;
// //     setCurrentIndex(newIndex);
// //   };

// //   const handleIndicatorClick = (index) => {
// //     setCurrentIndex(index);
// //   };

// //   useEffect(() => {
// //     const interval = setInterval(handleNext, autoSlideInterval);
// //     return () => clearInterval(interval); // Clear the interval on component unmount
// //   }, [currentIndex]);

// //   return (
// //     <div id="default-carousel" className="relative w-full" data-carousel="slide">
// //       {/* Carousel wrapper */}
// //       <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
// //         {images.map((image, index) => (
// //           <div
// //             key={index}
// //             className={`absolute block w-full h-full transition-opacity duration-700 ease-in-out ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
// //             data-carousel-item
// //           >
// //             <img src={image} className="block w-full h-full object-cover" alt={`carousel-${index + 1}`} />
// //           </div>
// //         ))}
// //       </div>

// //       {/* Slider indicators */}
// //       <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
// //         {images.map((_, index) => (
// //           <button
// //             key={index}
// //             type="button"
// //             className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-600' : 'bg-white'}`}
// //             aria-current={index === currentIndex}
// //             aria-label={`Slide ${index + 1}`}
// //             onClick={() => handleIndicatorClick(index)}
// //           ></button>
// //         ))}
// //       </div>

// //       {/* Slider controls */}
// //       <button
// //         type="button"
// //         className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
// //         onClick={handlePrev}
// //         data-carousel-prev
// //       >
// //         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
// //           <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
// //             <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
// //           </svg>
// //           <span className="sr-only">Previous</span>
// //         </span>
// //       </button>
// //       <button
// //         type="button"
// //         className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
// //         onClick={handleNext}
// //         data-carousel-next
// //       >
// //         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
// //           <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
// //             <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
// //           </svg>
// //           <span className="sr-only">Next</span>
// //         </span>
// //       </button>
// //     </div>
// //   );
// // };

// // export default Carousel;
