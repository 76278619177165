import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { SocialIcon } from 'react-social-icons';
import './style.css';

const FlipBox = ({
  id = '0',
  icon = [
    {
      name: 'icon',
      iconType: 'local',
      iconName: 'calendar',
      fgColor: '#2563eb',
      bgColor: 'black',
      show: 'none',
    },
  ],
  frontTitle = 'This is the heading',
  frontDescription = 'Welcome to our website! We are glad to have you here.',
  frontBackgroundColor = '#DED9DD',
  // frontImageResoluation = '100%',
  backBackgroundColor = '#dfc749',
  backTitle = 'This is the heading',
  backDescription = 'Welcome to our website! We are glad to have you here.',
  buttonText = 'Click Here',
  buttonUrl = 'http://example.com/1',
  flipInterval = 3, // interval in milliseconds (default: 3 seconds)
  flipBoxHeight = '200',
  borderRadius = '0',
  frontPadding = [0, 0, 0, 0],
  frontAlignment = 'justify-center',
  frontPosition = 'center',
  frontIconSpacing = '0',
  frontIconSize = '60',
  frontIconRotate = '',
  frontTitleSpacing = '0',
  frontTitleTextColor = '#594255',
  frontTextTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: '16px',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
    lineHeight: '100',
  },
  frontDescriptionTextColor = '#594255',
  frontDescriptionTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: '14px',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
    lineHeight: '100',
  },
  backPadding = [0, 0, 0, 0],
  backAlignment = 'justify-center',
  backPosition = 'center',
  backTitleTextColor = '#594255',
  backTitleSpacing = '0',
  backTextTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: '16px',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
    lineHeight: '100',
  },
  backDescriptionTextColor = '#594255',
  backDescriptionSpacing = '0',
  backDescriptionTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: '14px',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
    lineHeight: '100',
  },
  backButtonSize = 'px-4 py-2',
  backButtonfontColor = '#594255',
  backButtonBackgroundColor = '#ffff',
  backButtonBorderColor = '#594255',
  backButtonBorderWidth = '1',
  backButtonBorderRadius = '0',
  backButtonTypography = {
    type: 'editor',
    family: 'poppins, font-poppins',
    nameFontSize: '14px',
    typographyWeight: 'font-[600]',
    typographyStyle: 'normal',
    lineHeight: '100',
  },
  globalStyling = {
    fontColor: '#594255',
    textColor: '#594255',
    fontSize: 'text-xl',
    pmColor: '#fff',
    seColor: '#000000',
    groundColor: '#ffff',
    status: false,
    ignoreFieldArray: [] as string[],
    globalChangesList: [] as string[],
    buttonPadding: [] as number[],
    buttonMargin: [] as number[],
    buttonRadius: [] as number[],
  },
}) => {
  //   let handleGlobalStyling = () => {
  //     let data = {
  //       fontColor: globalStyling.fontColor,
  //       textFontSize: globalStyling.fontSize,
  //     };
  //     if (globalStyling.status) {
  //       if (globalStyling?.ignoreFieldArray?.length === 0) {
  //         if (globalStyling?.globalChangesList.includes('textColor')) {
  //           data.fontColor = globalStyling.fontColor;
  //           //  fontColor = globalStyling.fontColor;
  //         }
  //       } else {
  //         if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
  //           data.fontColor = fontColor;
  //           //  fontColor = fontColor;
  //         } else {
  //           data.fontColor = globalStyling.fontColor;
  //         }
  //       }
  //       if (globalStyling?.ignoreFieldArray?.length === 0) {
  //         if (globalStyling?.globalChangesList.includes('fontSize')) {
  //           data.textFontSize = globalStyling.fontSize;
  //           //  fontColor = globalStyling.fontColor;
  //         }
  //       } else {
  //         if (
  //           globalStyling?.ignoreFieldArray?.includes(
  //             'aboutUsTypography.nameFontSize',
  //           )
  //         ) {
  //           data.textFontSize = aboutUsTypography.nameFontSize;
  //         } else {
  //           data.textFontSize = globalStyling.fontSize;
  //         }
  //       }
  //     } else {
  //       if (globalStyling?.ignoreFieldArray?.includes('fontColor')) {
  //         data.fontColor = fontColor;
  //       }
  //       if (
  //         globalStyling?.ignoreFieldArray?.includes(
  //           'aboutUsTypography.nameFontSize',
  //         )
  //       ) {
  //         data.textFontSize = aboutUsTypography.nameFontSize;
  //       }
  //     }
  //     return data;
  //   };
  const api_url = import.meta.env.VITE_API_URL;
  const [isFlipped, setIsFlipped] = useState(false);

  let handleGlobalStyling = () => {
    let data = {
      icon: icon,
      backButtonBackgroundColor: globalStyling.groundColor,
      backButtonfontColor: globalStyling.fontColor,
      frontTitleTextColor: globalStyling.textColor,
      frontDescriptionTextColor: globalStyling.textColor,
      backTitleTextColor: globalStyling.textColor,
      backDescriptionTextColor: globalStyling.textColor,
    };
    if (globalStyling.status) {
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('pmColor')) {
          data.icon.filter((item) => {
            item.bgColor = globalStyling.pmColor;
          });
          // linkColor = globalStyling.linkColor;
        }
      } else {
        data.icon = icon.filter((item, index) => {
          if (
            globalStyling?.ignoreFieldArray?.includes(
              `icon-${id}-${index}-bgColor`,
            )
          ) {
            item.bgColor = item.bgColor;
            return item;
          } else {
            item.bgColor = globalStyling.pmColor;
            return item;
          }
        });
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('seColor')) {
          data.icon.filter((item) => {
            item.fgColor = globalStyling.seColor;
          });
        }
      } else {
        data.icon = icon.filter((item, index) => {
          if (
            globalStyling?.ignoreFieldArray?.includes(
              `icon-${id}-${index}-fgColor`,
            )
          ) {
            item.fgColor = item.fgColor;
            return item;
          } else {
            item.fgColor = globalStyling.seColor;
            return item;
          }
        });
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('groundColor')) {
          data.backButtonBackgroundColor = globalStyling.groundColor;
        }
      } else {
        if (
          globalStyling?.ignoreFieldArray?.includes('backButtonBackgroundColor')
        ) {
          data.backButtonBackgroundColor = backButtonBackgroundColor;
        } else {
          data.backButtonBackgroundColor = globalStyling.groundColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('textColor')) {
          data.frontTitleTextColor = globalStyling.textColor;
          data.frontDescriptionTextColor = globalStyling.textColor;
          data.backTitleTextColor = globalStyling.textColor;
          data.backDescriptionTextColor = globalStyling.textColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('frontTitleTextColor')) {
          data.frontTitleTextColor = frontTitleTextColor;
        } else {
          data.frontTitleTextColor = globalStyling.textColor;
        }
        if (
          globalStyling?.ignoreFieldArray?.includes('frontDescriptionTextColor')
        ) {
          data.frontDescriptionTextColor = frontDescriptionTextColor;
        } else {
          data.frontDescriptionTextColor = globalStyling.textColor;
        }
        if (globalStyling?.ignoreFieldArray?.includes('backTitleTextColor')) {
          data.backTitleTextColor = backTitleTextColor;
        } else {
          data.backTitleTextColor = globalStyling.textColor;
        }
        if (
          globalStyling?.ignoreFieldArray?.includes('backDescriptionTextColor')
        ) {
          data.backDescriptionTextColor = backDescriptionTextColor;
        } else {
          data.backDescriptionTextColor = globalStyling.textColor;
        }
      }
      if (globalStyling?.ignoreFieldArray?.length === 0) {
        if (globalStyling?.globalChangesList.includes('fontColor')) {
          data.backButtonfontColor = globalStyling.fontColor;
        }
      } else {
        if (globalStyling?.ignoreFieldArray?.includes('backButtonfontColor')) {
          data.backButtonfontColor = backButtonfontColor;
        } else {
          data.backButtonfontColor = globalStyling.fontColor;
        }
      }
    } else {
      data.icon = icon.filter((item, index) => {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            `icon-${id}-${index}-bgColor`,
          )
        ) {
          item.bgColor = item.bgColor;
          return item;
        } else {
          return item;
        }
      });
      data.icon = icon.filter((item, index) => {
        if (
          globalStyling?.ignoreFieldArray?.includes(
            `icon-${id}-${index}-fgColor`,
          )
        ) {
          item.fgColor = item.fgColor;
          return item;
        } else {
          return item;
        }
      });
      if (
        globalStyling?.ignoreFieldArray?.includes('backButtonBackgroundColor')
      ) {
        data.backButtonBackgroundColor = backButtonBackgroundColor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('backButtonfontColor')) {
        data.backButtonfontColor = backButtonfontColor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('frontTitleTextColor')) {
        data.frontTitleTextColor = frontTitleTextColor;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes('frontDescriptionTextColor')
      ) {
        data.frontDescriptionTextColor = frontDescriptionTextColor;
      }
      if (globalStyling?.ignoreFieldArray?.includes('backTitleTextColor')) {
        data.backTitleTextColor = backTitleTextColor;
      }
      if (
        globalStyling?.ignoreFieldArray?.includes('backDescriptionTextColor')
      ) {
        data.backDescriptionTextColor = backDescriptionTextColor;
      }
    }
    return data;
  };
  const [globalStylingData, setGlobalStylingData] = useState(() =>
    handleGlobalStyling(),
  );
  useEffect(() => {
    let data = handleGlobalStyling();
    setGlobalStylingData(data);
  }, [globalStyling]);

  // Automatically flip the card every few seconds
  useEffect(() => {
    const intervalSecond = flipInterval * 1000;

    const flipIntervalId = setInterval(() => {
      setIsFlipped((prev) => !prev);
    }, intervalSecond);

    // Cleanup interval on component unmount
    return () => clearInterval(flipIntervalId);
  }, [flipInterval]);

  return (
    <>
      <div className="my-4 flex flex-col">
        <div
          className="flip-card w-full"
          style={{
            height: `${flipBoxHeight}px`,
          }}
        >
          <div className={`flip-card-inner  ${isFlipped ? 'flipped' : ''}`}>
            <div
              className={`flip-card-front bg-[#DED9DD] text-white flex flex-col items-${frontPosition} text-${frontPosition} ${frontAlignment}`}
              style={{
                borderRadius: `${borderRadius}%`,
                padding: `${frontPadding?.[0]}px ${frontPadding?.[1]}px ${frontPadding?.[2]}px ${frontPadding?.[3]}px`,
                backgroundColor: frontBackgroundColor
              }}
            >
              <div
                style={{
                  paddingBottom: `${frontIconSpacing}px`,
                  rotate: `${frontIconRotate}deg`,
                }}
              >
                {globalStylingData.icon[0].show == 'icon' &&
                  globalStylingData.icon.length > 0 &&
                  globalStylingData.icon.map((link) => (
                    <>
                      {link.iconType === 'local' && (
                        <Icon
                          icon={link.iconName}
                          className={` rounded`}
                          color={link.fgColor}
                          // style={{ backgroundColor: link.bgColor }}
                          style={{
                            height: `${frontIconSize}px`,
                            width: `${frontIconSize}px`,
                          }}
                        />
                      )}
                      {link.iconType === 'browse' && (
                        <SocialIcon
                          url=""
                          // bgColor={link.bgColor}
                          fgColor={link.fgColor}
                          style={{
                            width: `${frontIconSize}px`,
                            height: `${frontIconSize}px`,
                          }}
                          as="div"
                        />
                      )}
                      {link.iconType === 'image' && (
                        <img
                          src={`${api_url}/public/cards/images/${link.iconName}`}
                          alt={link.name}
                          className=" rounded-full object-cover"
                          style={{
                            width: `${frontIconSize}px`,
                            height: `${frontIconSize}px`,
                          }}
                        />
                      )}
                    </>
                  ))}
              </div>
              <p
                className={`${frontTextTypography.typographyWeight}`}
                style={{
                  color: globalStylingData.frontTitleTextColor,
                  paddingBottom: `${frontTitleSpacing}px`,
                  fontFamily: frontTextTypography.family,
                  fontStyle: frontTextTypography.typographyStyle,
                  lineHeight: frontTextTypography.lineHeight,
                  fontSize: frontTextTypography.nameFontSize,
                }}
              >
                {frontTitle}
              </p>
              <p
                className={`${frontDescriptionTypography.typographyWeight}`}
                style={{
                  color: globalStylingData.frontDescriptionTextColor,
                  fontFamily: frontDescriptionTypography.family,
                  fontStyle: frontDescriptionTypography.typographyStyle,
                  lineHeight: frontDescriptionTypography.lineHeight,
                  fontSize: frontDescriptionTypography.nameFontSize,
                }}
              >
                {frontDescription}
              </p>
            </div>
            <div
              className={`flip-card-back bg-yellowtacx text-white flex flex-col items-${backPosition} text-${backPosition} ${backAlignment}`}
              style={{
                borderRadius: `${borderRadius}%`,
                padding: `${backPadding?.[0]}px ${backPadding?.[1]}px ${backPadding?.[2]}px ${backPadding?.[3]}px`,
                backgroundColor: backBackgroundColor
              }}
            >
              <p
                className={`${backTextTypography.typographyWeight}`}
                style={{
                  color: globalStylingData.backTitleTextColor,
                  paddingBottom: `${backTitleSpacing}px`,
                  fontFamily: backTextTypography.family,
                  fontStyle: backTextTypography.typographyStyle,
                  lineHeight: backTextTypography.lineHeight,
                  fontSize: backTextTypography.nameFontSize,
                }}
              >
                {backTitle}
              </p>
              <p
                className={`${backDescriptionTypography.typographyWeight}`}
                style={{
                  color: globalStylingData.backDescriptionTextColor,
                  paddingBottom: `${backDescriptionSpacing}px`,
                  fontFamily: backDescriptionTypography.family,
                  fontStyle: backDescriptionTypography.typographyStyle,
                  lineHeight: backDescriptionTypography.lineHeight,
                  fontSize: backDescriptionTypography.nameFontSize,
                }}
              >
                {backDescription}
              </p>
              <a
                href={buttonUrl}
                target="_blank"
                className={`border ${backButtonSize} mt-3 ${backButtonTypography.typographyWeight}`}
                style={{
                  color: globalStylingData.backButtonfontColor,
                  borderColor: backButtonBorderColor,
                  borderWidth: `${backButtonBorderWidth}px`,
                  borderRadius: `${backButtonBorderRadius}%`,
                  fontFamily: backButtonTypography.family,
                  fontStyle: backButtonTypography.typographyStyle,
                  lineHeight: backButtonTypography.lineHeight,
                  fontSize: backButtonTypography.nameFontSize,
                  backgroundColor: globalStylingData.backButtonBackgroundColor,
                }}
              >
                {buttonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlipBox;
