import { useNavigate } from 'react-router-dom';
import MyIcon from '../../components/CardEditor/icons/MyIcon';
import { useEffect, useRef, useState } from 'react';
import { getCountry, upgradePlan } from '../../api/api';
import Header from '../../components/Header/SubHeader';
import Loader from '../../common/Loader';
import CustomLoader from '../../common/Loader/customLoader';
import { useSelector } from 'react-redux';
import { selectSubscription } from '../../store/slice/UserSlice';

const UpgradePlan = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [addUser, setAddUser] = useState<number>(1);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const comparisonSectionRef = useRef<HTMLDivElement>(null);
  // const [planPrices, setPlanPrices] = useState<{ [key: string]: number }>({
  //   Professional: 199,
  //   Team: 499,
  //   Enterprise: 999,
  // });
  const [planPrices, setPlanPrices] = useState<{
    [key: string]: {
      monthly: number;
      yearly: number;
    };
  }>({
    Professional: {
      monthly: 199,
      yearly: 1990,
    },
    Team: {
      monthly: 499,
      yearly: 4990,
    },
    Enterprise: {
      monthly: 999,
      yearly: 9990,
    },
  });
  const [userLocation, setUserLocation] = useState('');
  const [loader, setLoader] = useState(true);
  const [upgradePlanLoader, setUpgradePlanLoader] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  const [selectedInterval, setSelectedInterval] = useState<
    'monthly' | 'yearly'
  >('monthly');
  const [confirmVisible, setConfirmVisible] = useState(false);

  const subscriptionDetails = useSelector(selectSubscription);
  const isCurrentPlan = (plan: any, interval: any) =>
    subscriptionDetails?.plan_name === plan &&
    subscriptionDetails?.subscription_period === interval;

  // Determine if a plan can be selected based on the current plan
  // const canSelectPlan = (plan: any, interval: string) => {
  //   if (isCurrentPlan('Enterprise', interval)) {
  //     return false; // If the current plan is Enterprise, no downgrades are allowed
  //   }
  //   if (
  //     (isCurrentPlan('Team', interval) && plan === 'Professional', interval)
  //   ) {
  //     return false; // If the current plan is Team, you can't downgrade to Professional
  //   }
  //   return true; // Otherwise, the plan can be selected
  // };
  const canSelectPlan = (plan: string, interval: string) => {
    const currentPlan = subscriptionDetails?.plan_name;
    const currentInterval = subscriptionDetails?.subscription_period;

    if (currentPlan === 'Enterprise' && currentInterval === 'year') {
      // Enterprise yearly: No plans should be available
      return false;
    }
    if (currentPlan === 'Enterprise' && currentInterval === 'month') {
      // Enterprise monthly: Can upgrade to Professional yearly, Team yearly, or Enterprise yearly
      return (
        (plan === 'Professional' && interval === 'year') ||
        (plan === 'Team' && interval === 'year') ||
        (plan === 'Enterprise' && interval === 'year')
      );
    }

    if (currentPlan === 'Team' && currentInterval === 'month') {
      // Team monthly: No downgrade to Professional monthly but can upgrade to Enterprise monthly or any yearly plans
      return (
        (plan === 'Enterprise' && interval === 'month') ||
        (plan === 'Enterprise' && interval === 'year') ||
        (plan === 'Professional' && interval === 'year') ||
        (plan === 'Team' && interval === 'year')
      );
    }

    if (currentPlan === 'Professional' && currentInterval === 'month') {
      // Professional monthly: Can upgrade to all plans monthly and yearly
      return (
        (plan === 'Team' && interval === 'month') ||
        (plan === 'Enterprise' && interval === 'month') ||
        (plan === 'Professional' && interval === 'year') ||
        (plan === 'Team' && interval === 'year') ||
        (plan === 'Enterprise' && interval === 'year')
      );
    }

    if (currentPlan === 'Professional' && currentInterval === 'year') {
      // Professional yearly: Can upgrade to Team and Enterprise yearly, but cannot select any monthly plans
      return (
        (plan === 'Team' && interval === 'year') ||
        (plan === 'Enterprise' && interval === 'year')
      );
    }

    if (currentPlan === 'Team' && currentInterval === 'year') {
      // Team yearly: Can only upgrade to Enterprise yearly; no selection allowed for monthly plans or Professional yearly
      return plan === 'Enterprise' && interval === 'year';
    }

    // Default to true if no specific rules apply
    return true;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoader(true);
      const countryData = await getCountry();
      let country = 'IN';
      if (countryData) {
        setLoader(false);
        country = countryData?.country || 'IN';
      } else {
        setLoader(false);
        country = 'IN';
      }
      setUserLocation(country);
      if (country === 'IN') {
        // setPlanPrices({
        //   Professional: {199},
        //   Team: 499,
        //   Enterprise: 999,
        // });
        setPlanPrices({
          Professional: {
            monthly: 199,
            yearly: 1990,
          },
          Team: {
            monthly: 499,
            yearly: 4990,
          },
          Enterprise: {
            monthly: 999,
            yearly: 9990,
          },
        });
      } else if (country === 'US') {
        setPlanPrices({
          Professional: {
            monthly: 9,
            yearly: 90,
          },
          Team: {
            monthly: 99,
            yearly: 990,
          },
          Enterprise: {
            monthly: 999,
            yearly: 9990,
          },
        });
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (
      subscriptionDetails &&
      subscriptionDetails.subscription_period == 'year'
    ) {
      setIsIndividual(false);
      setSelectedInterval('yearly');
    }
  }, [subscriptionDetails]);

  const handleIncrease = () => {
    if (selectedPlan == 'Team') {
      setAddUser((prevCount) => (prevCount < 6 ? prevCount + 1 : prevCount)); //maximum 10 users
    } else if (selectedPlan == 'Enterprise') {
      setAddUser((prevCount) => (prevCount < 20 ? prevCount + 1 : prevCount)); //maximum 10 users
    }
  };
  const handleDecrease = () => {
    // setAddUser((prevCount) => (prevCount > 0 ? prevCount - 1 : 0)); //minimum 0
    setAddUser((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount)); //minimum 1 user
  };

  const handleSelectPlan = (plan: string) => {
    setAddUser(1);
    setSelectedPlan(plan === selectedPlan ? null : plan);
  };

  const handleToggle = (plan: string) => {
    if (plan === 'month' && !isIndividual) {
      setIsIndividual(true);
      setSelectedPlan(null);
      setAddUser(1);
      setSelectedInterval('monthly');
    } else if (plan === 'year' && isIndividual) {
      setIsIndividual(false);
      setSelectedPlan(null);
      setAddUser(1);
      setSelectedInterval('yearly');
    }
  };

  const handleUpgradePlan = async () => {
    setConfirmVisible(true);
  };

  const handleConfirmUpgradePlan = async () => {
    if (selectedPlan) {
      setConfirmVisible(false);
      try {
        setUpgradePlanLoader(true);
        const addUsers = selectedPlan === 'Professional' ? 0 : addUser;
        const response = await upgradePlan({
          selectedPlan,
          addUsers,
          userLocation,
          shipping_cost: 0,
          selectedInterval: selectedInterval,
        });
        if (response?.data.success) {
          setTimeout(() => {
            setUpgradePlanLoader(false);
            navigate('/account-billing');
          }, 3000);
        } else {
          setUpgradePlanLoader(false);
          console.error('Upgrade failed:', response?.data.error); // Handle the failure case
        }
      } catch (error) {
        setUpgradePlanLoader(false);
        console.error('Error upgrading plan:', error); // Log any errors that occur
      }
    }
  };

  return (
    <>
      <Header
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        onAboutUsClick={() => null}
        onPricingClick={() => null}
        onContactUsClick={() => null}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          {upgradePlanLoader && <CustomLoader />}
          <div className="p-10 h-screen font-poppins ">
            <div className="mt-8 mb-8">
              <h1 className="text-black text-[30px] font-semibold text-center ">
                Upgrade Plan
              </h1>
            </div>
            <div className="flex flex-row flex-wrap gap-3 justify-center">
              <div className="bg-[#B6B6B6] p-2 rounded-full shadow-lg w-1/2">
                {/* Toggle Switch */}
                <div className="flex bg-gray-300 rounded-full">
                  <button
                    className={`flex items-center justify-center h-14 w-1/2 rounded-full transition-colors duration-300 ease-in-out ${
                      isIndividual
                        ? 'bg-white text-black shadow-md'
                        : 'bg-gray-300 text-gray-600'
                    }`}
                    onClick={() => handleToggle('month')}
                  >
                    <div className="flex items-center space-x-2 text-[20px]">
                      <span>Monthly Plans</span>
                    </div>
                  </button>
                  <button
                    className={`flex items-center justify-center h-14 w-1/2 rounded-full transition-colors duration-300 ease-in-out ${
                      !isIndividual
                        ? 'bg-white text-black shadow-md'
                        : 'bg-gray-300 text-gray-600'
                    }`}
                    onClick={() => handleToggle('year')}
                  >
                    <div className="flex items-center space-x-2 text-[20px]">
                      <span>Yearly Plans</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            {isIndividual ? (
              <>
                <div className="overflow-auto">
                  <div className="flex flex-col items-center ">
                    <div className="flex flex-row flex-wrap gap-5 mt-15 justify-center">
                      <div
                        className={`${
                          selectedPlan === 'Professional'
                            ? 'border border-3 border-purpletacx p-8 rounded-lg text-purpletacx '
                            : 'bg-purpletacx p-8 rounded-lg text-white'
                        } w-[450px] h-[650px]`}
                      >
                        <p className="font-semibold text-2xl mb-10">
                          Professional
                        </p>
                        <p className="font-light text-base mb-2">
                          For individuals
                        </p>
                        <p className="font-semibold text-xl">{`${userLocation == 'IN' ? `₹` : `$`}${planPrices.Professional.monthly}/month`}</p>
                        {isCurrentPlan('Professional', 'month') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : canSelectPlan('Professional', 'month') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => handleSelectPlan('Professional')}
                          >
                            Select plan
                          </button>
                        ) : (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                            disabled
                          >
                            Unavailable
                          </button>
                        )}
                        <div className="mt-12 flex flex-col gap-4 text-base">
                          <p className="font-medium">Includes:</p>
                          <p className="gap-3 flex">
                            <span>✓</span> Advance card designer
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Free TACX smart card
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Analytics
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Email support
                          </p>
                        </div>
                      </div>

                      <div
                        className={`${
                          selectedPlan === 'Team'
                            ? 'border border-3 border-purpletacx p-8 rounded-lg text-purpletacx '
                            : 'bg-purpletacx p-8 rounded-lg text-white'
                        } w-[450px] h-[650px]`}
                      >
                        <p className="font-semibold text-2xl mb-10">Team</p>
                        <p className="font-light text-base mb-2">
                          1 user included!
                        </p>
                        <p className="font-semibold text-xl">{`${userLocation == 'IN' ? `₹` : `$`}${planPrices.Team.monthly}/month`}</p>
                        {isCurrentPlan('Team', 'month') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Team' ? (
                          <>
                            <hr />
                            <div className="p-3">
                              <p className="text-sm">Add users</p>
                              <div className="flex flex-row justify-between w-20 bg-purpletacx text-white rounded-full items-center p-2">
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleDecrease}
                                >
                                  -
                                </button>
                                <input
                                  value={addUser}
                                  className="h-5 w-5 bg-transparent border-none text-center items-center flex justify-center"
                                  readOnly
                                />
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleIncrease}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <hr />
                            <button
                              className="bg-purpletacx text-white py-4  w-full rounded-full text-lg font-semibold mt-8 hover:bg-yellowtacx"
                              onClick={handleUpgradePlan}
                            >
                              Upgrade Plan
                            </button>
                          </>
                        ) : canSelectPlan('Team', 'month') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => handleSelectPlan('Team')}
                          >
                            Select plan
                          </button>
                        ) : (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                            disabled
                          >
                            Unavailable
                          </button>
                        )}
                        <div className="mt-12 flex flex-col gap-4 text-base ">
                          <p className="font-semibold">
                            Everything in "Professional" plus:
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Organize users by team
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> User content control
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> User access levels
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Team analytics
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Priority support
                          </p>
                        </div>
                      </div>

                      <div
                        className={`${
                          selectedPlan === 'Enterprise'
                            ? 'border border-3 border-purpletacx p-8 rounded-lg text-purpletacx '
                            : 'bg-purpletacx p-8 rounded-lg text-white '
                        } w-[450px] h-[650px]`}
                      >
                        <p className="font-semibold text-2xl mb-8">
                          Enterprise
                        </p>
                        <p className="font-medium text-lg">20+ Users</p>
                        <p className="font-extralight text-base">
                          No user limits!
                        </p>
                        <p className="font-semibold text-xl">{`${userLocation == 'IN' ? `₹` : `$`}${planPrices.Enterprise.monthly}/month`}</p>
                        {
                          isCurrentPlan('Enterprise', 'month') ? (
                            <button
                              className="mt-8 text-purpletacx bg-white py-4 px-28 rounded-full text-lg font-bold w-[380px]"
                              disabled
                            >
                              Current Plan
                            </button>
                          ) : selectedPlan === 'Enterprise' ? (
                            <>
                              <hr />
                              <div className="p-3">
                                <p className="text-sm">Add users</p>
                                <div className="flex flex-row justify-between w-20 bg-purpletacx text-white rounded-full items-center p-2">
                                  <button
                                    className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                    onClick={handleDecrease}
                                  >
                                    -
                                  </button>
                                  <input
                                    value={addUser}
                                    className="h-5 w-5 bg-transparent border-none text-center items-center flex justify-center"
                                    readOnly
                                  />
                                  <button
                                    className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                    onClick={handleIncrease}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <hr />
                              <button
                                className="bg-purpletacx text-white py-4 w-full rounded-full text-lg font-semibold mt-8 hover:bg-yellowtacx"
                                onClick={handleUpgradePlan}
                              >
                                Upgrade Plan
                              </button>
                            </>
                          ) : canSelectPlan('Enterprise', 'month') ? (
                            <button
                              className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold hover:bg-yellowtacx"
                              onClick={() => handleSelectPlan('Enterprise')}
                            >
                              Select plan
                            </button>
                          ) : (
                            <button
                              className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                              disabled
                            >
                              Unavailable
                            </button>
                          )
                          // : (
                          //   <button
                          //     className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold hover:bg-yellowtacx"
                          //     onClick={() => handleSelectPlan('Enterprise')}
                          //   >
                          //     Select plan
                          //   </button>
                          // )
                        }
                        <div className="mt-12 flex flex-col gap-4 text-base">
                          <p className="font-semibold">
                            Everything in “Team” plus:
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Dedicated account manager
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Full concierge service available
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="overflow-auto">
                  <div className="flex flex-col items-center">
                    <div className="flex flex-row flex-wrap gap-5 mt-15 justify-center">
                      <div
                        className={`${
                          selectedPlan === 'Professional'
                            ? 'border border-3 border-purpletacx p-8 rounded-lg text-purpletacx '
                            : 'bg-purpletacx p-8 rounded-lg text-white '
                        } w-[450px] h-[650px]`}
                      >
                        <p className="font-semibold text-2xl mb-10">
                          Professional (year)
                        </p>
                        <p className="font-light text-base mb-2">
                          For individuals
                        </p>
                        <p className="font-semibold text-xl">{`${userLocation === 'IN' ? `₹` : `$`}${planPrices.Professional.yearly}/year`}</p>
                        {isCurrentPlan('Professional', 'year') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Professional' ? (
                          <>
                            <button
                              className="bg-purpletacx text-white py-4  w-full rounded-full text-lg font-semibold mt-8 hover:bg-yellowtacx"
                              onClick={handleUpgradePlan}
                            >
                              Upgrade Plan
                            </button>
                          </>
                        ) : canSelectPlan('Professional', 'year') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => handleSelectPlan('Professional')}
                          >
                            Select plan
                          </button>
                        ) : (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                            disabled
                          >
                            Unavailable
                          </button>
                        )}
                        <div className="mt-12 flex flex-col gap-4 text-base">
                          <p className="font-medium">Includes:</p>
                          <p className="gap-3 flex">
                            <span>✓</span> Advance card designer
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Free TACX smart card
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Analytics
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Email support
                          </p>
                        </div>
                      </div>

                      <div
                        className={`${
                          selectedPlan === 'Team'
                            ? 'border border-3 border-purpletacx p-8 rounded-lg text-purpletacx '
                            : 'bg-purpletacx p-8 rounded-lg text-white '
                        } w-[450px] h-[650px]`}
                      >
                        <p className="font-semibold text-2xl mb-10">
                          Team (year)
                        </p>
                        <p className="font-light text-base mb-2">
                          1 user included!
                        </p>
                        <p className="font-semibold text-xl">{`${userLocation === 'IN' ? `₹` : `$`}${planPrices.Team.yearly}/year`}</p>

                        {isCurrentPlan('Team', 'year') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Team' ? (
                          <>
                            <hr />
                            <div className="p-3">
                              <p className="text-sm">Add users</p>
                              <div className="flex flex-row justify-between w-20 bg-purpletacx text-white rounded-full items-center p-2">
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleDecrease}
                                >
                                  -
                                </button>
                                <input
                                  value={addUser}
                                  className="h-5 w-5 bg-transparent border-none text-center items-center flex justify-center"
                                  readOnly
                                />
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleIncrease}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <hr />
                            <button
                              className="bg-purpletacx text-white py-4  w-full rounded-full text-lg font-semibold mt-8 hover:bg-yellowtacx"
                              onClick={handleUpgradePlan}
                            >
                              Upgrade Plan
                            </button>
                          </>
                        ) : canSelectPlan('Team', 'year') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => handleSelectPlan('Team')}
                          >
                            Select plan
                          </button>
                        ) : (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                            disabled
                          >
                            Unavailable
                          </button>
                        )}
                        <div className="mt-12 flex flex-col gap-4 text-base">
                          <p className="font-semibold">
                            Everything in “Professional” plus:
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Organize users by team
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> User content control
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> User access levels
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Team analytics
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Priority support
                          </p>
                        </div>
                      </div>

                      <div
                        className={`${
                          selectedPlan === 'Enterprise'
                            ? 'border border-3 border-purpletacx p-8 rounded-lg text-purpletacx '
                            : 'bg-purpletacx p-8 rounded-lg text-white '
                        } w-[450px] h-[650px]`}
                      >
                        <p className="font-semibold text-2xl mb-8">
                          Enterprise (year)
                        </p>
                        <p className="font-medium text-lg">20+ Users</p>
                        <p className="font-extralight text-base">
                          No user limits!
                        </p>
                        <p className="font-semibold text-xl">{`${userLocation === 'IN' ? `₹` : `$`}${planPrices.Enterprise.yearly}/year`}</p>

                        {isCurrentPlan('Enterprise', 'year') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 px-28 rounded-full text-lg font-bold w-[380px]"
                            disabled
                          >
                            Current Plan
                          </button>
                        ) : selectedPlan === 'Enterprise' ? (
                          <>
                            <hr />
                            <div className="p-3">
                              <p className="text-sm">Add users</p>
                              <div className="flex flex-row justify-between w-20 bg-purpletacx text-white rounded-full items-center p-2">
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleDecrease}
                                >
                                  -
                                </button>
                                <input
                                  value={addUser}
                                  className="h-5 w-5 bg-transparent border-none text-center items-center flex justify-center"
                                  readOnly
                                />
                                <button
                                  className="bg-white rounded-full h-5 w-5 text-purpletacx items-center flex justify-center"
                                  onClick={handleIncrease}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <hr />
                            <button
                              className="bg-purpletacx text-white py-4 w-full rounded-full text-lg font-semibold mt-8 hover:bg-yellowtacx"
                              onClick={handleUpgradePlan}
                            >
                              Upgrade Plan
                            </button>
                          </>
                        ) : canSelectPlan('Enterprise', 'year') ? (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold hover:bg-yellowtacx"
                            onClick={() => handleSelectPlan('Enterprise')}
                          >
                            Select plan
                          </button>
                        ) : (
                          <button
                            className="mt-8 text-purpletacx bg-white py-4 w-full rounded-full text-lg font-bold"
                            disabled
                          >
                            Unavailable
                          </button>
                        )}
                        <div className="mt-12 flex flex-col gap-4 text-base">
                          <p className="font-semibold">
                            Everything in “Team” plus:
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Dedicated account manager
                          </p>
                          <p className="gap-3 flex">
                            <span>✓</span> Full concierge service available
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              ref={comparisonSectionRef}
              className="flex flex-col justify-center content-center items-center pb-10 mt-10"
            >
              <h1 className="text-black font-semibold text-center text-[20px]">
                Plan Comparison
              </h1>
              <table className="table-auto border border-3 border-purpletacx rounded-md mt-10 overflow-x-auto text-black w-[96%]">
                <tbody className="text-sm">
                  <tr className="text-left border">
                    <th className="p-8">Feature</th>
                    <th>Free</th>
                    <th>Professional</th>
                    <th>Team</th>
                  </tr>
                  <tr>
                    <td className="p-5 font-bold">CARD DESIGNER</td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">Design Elements</td>
                    <td>Basic</td>
                    <td>Advanced</td>
                    <td>Advanced</td>
                  </tr>
                  <tr>
                    <td className="p-3 pl-10 font-semibold">Card Styling</td>
                    <td>Basic</td>
                    <td>Advanced</td>
                    <td>Advanced</td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">
                      Social Profile Hub
                    </td>
                    <td className="font-semibold">Up to 4 social links</td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3 pl-10 font-semibold">
                      Create Templates
                    </td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">Call to actions</td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3 pl-10 font-semibold">
                      Testimonial Wall
                    </td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">Photo Gallery</td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3 pl-10 font-semibold">
                      Youtube/Vimeo videos*
                    </td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td className="font-semibold">Up to 5</td>
                    <td className="font-semibold">Up to 6 per user</td>
                  </tr>

                  <tr>
                    <td className="p-5 pt-10 font-bold">
                      COMPANY & USER PROFILES
                    </td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">
                      Team member management
                    </td>
                    <td>
                      {' '}
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      {' '}
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3 pl-10 font-semibold">
                      Organize users by team
                    </td>
                    <td>
                      {' '}
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      {' '}
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>Up to 6 team</td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">
                      User content control
                    </td>
                    <td className="font-semibold">
                      {' '}
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3 pl-10 font-semibold">Custom fields</td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">
                      Link your CRM form
                    </td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-3 pl-10 font-semibold">
                      Link your booking software
                    </td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">Custom links</td>
                    <td>
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>

                  <tr>
                    <td className="p-5 pt-10 font-bold">ANALYTICS</td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">Analytics</td>
                    <td>
                      {' '}
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      {' '}
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td>
                      <MyIcon iconName="checkSmall" />
                    </td>
                  </tr>

                  <tr>
                    <td className="p-5 pt-10 font-bold">INTEGRATIONS</td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">
                      Third part Testimonials
                    </td>
                    <td>
                      {' '}
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>Coming soon</td>
                    <td>Coming soon</td>
                  </tr>
                  <tr>
                    <td className="p-3 pl-10 font-semibold">Zapier</td>
                    <td>
                      {' '}
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>Coming soon</td>
                    <td>Coming soon</td>
                  </tr>

                  <tr>
                    <td className="p-5 pt-10 font-bold">SUPPORT</td>
                  </tr>
                  <tr className="bg-[#5942551A]">
                    <td className="p-3 pl-10 font-semibold">Email</td>
                    <td>
                      {' '}
                      <MyIcon iconName="uncheckSmall" />
                    </td>
                    <td>
                      {' '}
                      <MyIcon iconName="checkSmall" />
                    </td>
                    <td className="flex gap-2 mt-3">
                      <MyIcon iconName="checkSmall" />
                      <span>Priority</span>
                    </td>
                  </tr>
                  <tr className="h-20">
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            {confirmVisible && (
              <>
                <div className="fixed grid content-center top-0 bottom-0 left-0 z-[999999] w-screen h:screen bg-[#59425580] bg-opacity-70 font-poppins fade-in-animate">
                  <div className="bg-[#ffff] flex m-auto justify-center rounded-lg relative w-[90%] md:w-1/2 xl:w-1/3 slideInFromTop-animate">
                    <div className="p-5 sm:p-10 py-15 w-full flex flex-col items-center">
                      <div className="flex flex-col gap-3 w-full text-black">
                        <div className="flex justify-between">
                          <div>
                            <p className="text-lg font-semibold">
                              {selectedPlan}
                            </p>
                          </div>
                          <div>
                            {selectedPlan && (
                              <p className="font-semibold text-lg">{`${userLocation == 'IN' ? `₹` : `$`}${planPrices[selectedPlan][selectedInterval]}`}</p>
                            )}
                          </div>
                        </div>
                        {addUser > 1 && (
                          <div className="flex justify-between">
                            <div>
                              <p className="font-semibold text-lg">
                                Additional Users
                              </p>
                              <p className="text-sm">Qty {addUser - 1}</p>
                            </div>
                            <div>
                              <p className="text-end font-semibold text-lg">
                                {`${userLocation == 'IN' ? `₹` : `$`}${(addUser - 1) * (selectedInterval === 'monthly' ? 10 : 20)}`}
                              </p>
                              <p className="text-sm">
                                {' '}
                                {selectedInterval == 'monthly'
                                  ? `${userLocation == 'IN' ? `₹` : `$`}10.00 each`
                                  : `${userLocation == 'IN' ? `₹` : `$`}20.00 each`}
                              </p>
                            </div>
                          </div>
                        )}
                        <hr />
                        <div className="flex justify-between">
                          <div>
                            <p>
                              <span className="font-semibold text-lg">
                                Total
                              </span>{' '}
                              {selectedInterval == 'monthly' ? (
                                <span className="text-sm">
                                  (Billed monthly)
                                </span>
                              ) : (
                                <span className="text-sm">(Billed yearly)</span>
                              )}
                            </p>
                          </div>
                          <div>
                            {selectedPlan && (
                              <p className="font-semibold text-lg">{`${userLocation == 'IN' ? `₹` : `$`}${planPrices[selectedPlan][selectedInterval] + (addUser - 1) * (selectedInterval === 'monthly' ? 10 : 20)}`}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-10 w-full flex gap-2 justify-between">
                        <button
                          className="flex justify-center py-2 w-full border border-purpletacx text-black font-semibold rounded-full"
                          onClick={() => {
                            setConfirmVisible(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="flex justify-center py-2 w-full bg-[#DFC749] text-black font-semibold rounded-full"
                          onClick={handleConfirmUpgradePlan}
                        >
                          Confirm
                        </button>
                      </div>

                      <p className="mt-3 text-sm">
                        <span className="font-semibold">Note:</span> By
                        confirming, you agree to upgrade your plan, and the
                        payment will be processed, deducting the amount from
                        your account.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UpgradePlan;
